import { InjectionToken } from "@angular/core";

export interface AppConfig {
  apiEndPoint: string;
}

export const appConfig: AppConfig = {
  apiEndPoint: ''
};

export const APP_CONFIG = new InjectionToken<AppConfig>('appConfig');
