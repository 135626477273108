    <div class="px-20 py-24 w-full">
      <div class="w-full items-center flex pb-8">
        <span class="text-gray700 text-14 NotoSansGeorgianSemiBold mr-10">არჩეული პოლისები</span>
        <i class="w-24 h-24 rounded-12 bg-gray10 hover:bg-blue200 flex justify-center text-white items-center text-14">0</i>
      </div>
      <button (click)="getReport()"
        class="bg-gray600 h-60 p-20 mb-20 rounded-20 border-0 focus:outline-none hover:bg-opacity-75 flex w-full items-center">
        <img src="../assets/images/icons/Vector3.png" alt="" class="mr-10">
        <span class="MYRIADGEOMTAVRULIBOLD text-16 text-gray700">რეპორტი</span>
        <img src="../assets/images/icons/arrow.png" alt="" class="ml-auto">
      </button>
      <button (click)="reviewPayment()"  class="bg-pink h-60 p-20 mb-20 rounded-20 border-0  flex w-full items-center cursor-pointer"
      [ngClass]="(policy.allDebet<1)? 'opacity-50':''"
      [disabled] = "policy.allDebet<1 ? true: false"
      >
        <img src="../assets/images/icons/Vector1.png" alt="" class="mr-10 relative">
        <span class="text-16 MYRIADGEOMTAVRULIBOLD text-white relative">გადახდა</span>
        <span class="ml-auto text-16 text-white relative">{{policy.allDebet}}</span>
        <span class="ml-2 text-16 text-white relative">₾</span>
      </button>
      <div class="border-t border-black opacity-10 w-full pb-20"></div>
      <div class="bg-blue300 p-20 rounded-20 border-0 focus:outline-none flex flex-col w-full h-full"  (click)="checkOverdue()">
        <img src="../assets/images/icons/Group.png" alt="" class="mr-auto mb-12">
        <div class="flex items-center">
          <div class="pt-14">
            <p class=" text-18 MYRIADGEOMTAVRULIBOLD text-white">ახალი პოლისის დამატება</p>
          </div>
          <a
            class="ml-auto transform hover:scale-105 transition duration-300 cursor-pointer bg-white bg-opacity-10 rounded-20 w-40 h-40 flex items-center justify-center">
            <img src="../assets/images/icons/Vector4.png" alt="" class="">
          </a>
        </div>
      </div>
    </div>
