<div class="my-49 md:max-w-full md:mx-30 h-470">
  <div class="flex flex-col">
    <div class="min-w-full align-middle">
      <div class="">
        <table class="w-full whitespace-nowrap">
          <thead class="border-b border-black border-opacity-5">
            <tr>
              <th></th>
              <th scope="col" class="">
                <div class="flex items-center cursor-pointer group-hover pb-16">
                  <span
                    class="text-10 md:text-12 NotoSansGeorgianMedium text-blue300 text-opacity-70 group-hover:text-blue">საზღვაო
                    ხაზი</span>
                </div>
              </th>
              <th scope="col" class="">
                <div class="flex items-center cursor-pointer group-hover pb-16">
                  <span class="text-10 md:text-12 NotoSansGeorgianMedium text-blue300 text-opacity-70
                    ml-15">კონტეინერები</span>
                </div>
              </th>
              <th scope="col" class="">
                <div class="flex items-center cursor-pointer group-hover pb-16">
                  <span
                    class="text-10 md:text-12 NotoSansGeorgianMedium text-blue300 text-opacity-70 group-hover:text-blue">პრემია</span>
                </div>
              </th>
              <th scope="col" class="">
                 <div class="flex items-center cursor-pointer group-hover pb-16">
                   <span
                     class="text-10 md:text-12 NotoSansGeorgianMedium text-blue300 text-opacity-70 group-hover:text-blue">ქმედებები</span>
                 </div>
              </th>
            </tr>
          </thead>

          <tbody class="">
            <tr *ngFor="let item of this.data?.applications | paginate: {
                       id: 'listing_pagination1',
                       itemsPerPage: 8,
                       currentPage: page,
                       totalItems: this.data?.count}" class="h-54 items-center border-b border-black border-opacity-5">
              <td class="">
                <div class="flex justify-center items-center">
                  <i class="w-8 h-8 bg-gray200 rounded-40 -mt-4"></i>
                </div>
              </td>
              <td class="pl-2">
                <span class="md:text-12 text-10 NotoSansGeorgianSemiBold text-gray700">
                  {{ item.beneficiaryFullName }}
                </span>
              </td>
              <td>
                <button [matMenuTriggerFor]="menuContainer"
                  class="bg-blue400 rounded-20 py-10 flex items-center pr-16 pl-8">
                  <span
                    class="rounded-40 bg-blue500 w-20 h-20 text-center items-center text-white md:text-12 text-10 font-bold justify-center flex">{{item.containersCount}}</span>
                  <span class="text-gray700 text-10 md:text-12 font-semibold ml-8">{{ item.containerTypeText
                            }}</span>
                </button>
                <mat-menu #menuContainer="matMenu">
                  <div class="flex justify-center flex-col w-full min-w-426">
                    <div
                      class="absolute rounded-3 border-l border-t border-blue600 w-14 h-14 -top-7 left-10 rotate-45 bg-white">
                    </div>
                    <div class="flex items-center px-16 mb-16">
                      <span class="NotoSansGeorgianSemiBold text-14 text-gray700">კონტეინერის ნომრები</span>
                      <span class="ml-auto">
                        <div routerlink="/" class="bg-gray5 rounded-40 p-11 cursor-pointer">
                          <img src="../assets/images/icons/VectorX.png" alt="">
                        </div>
                      </span>
                    </div>
                    <div class="w-full">
                      <div class="flex pl-40 pr-12 py-8 text-left" *ngFor="let item of item.containers">
                        <span class="uppercase tracking-15 text-14 font-bold text-blue text-opacity-80">
                          {{item}}
                        </span>
                      </div>
                    </div>
                  </div>
                </mat-menu>
              </td>
              <td class="text-12 font-bold text-gray700 items-center">
                <span> {{ item.premium }} </span>
                <span>$</span>
              </td>
              <td class="w-30 h-30 pl-20">
                <button type="button" [matMenuTriggerFor]="menu">
                  <div class="w-30 h-30 rounded-40 flex items-center justify-center">
                    <img src="../assets/images/icons/tu.png" alt="">
                  </div>
                </button>
                <mat-menu #menu="matMenu">
                  <div class="flex justify-center flex-col w-full">
                    <div
                      class="absolute rounded-3 border-l border-t border-blue600 w-14 h-14 -top-7 left-10 rotate-45 bg-white">
                    </div>
                    <div
                      class="hover:bg-white200 px-20 pt-14  pb-12 border-b border-black border-opacity-5 cursor-pointer"
                      (click)="confirm(item.applicationID)">
                      <span class="NotoSansGeorgianSemiBold text-12 text-gray700">დადასტურება</span>
                    </div>
                    <div class="hover:bg-white200 px-20 py-12 border-b border-black border-opacity-5 cursor-pointer"
                      [routerLink]="['/application/',item.applicationID]">
                      <span class="NotoSansGeorgianSemiBold text-12 text-gray700">რედაქტირება</span>
                    </div>
                    <div class="hover:bg-white200 px-20 py-12 cursor-pointer" (click)="delete(item.applicationID)">
                      <span class="NotoSansGeorgianSemiBold text-12 text-gray700">წაშლა</span>
                    </div>
                  </div>
                </mat-menu>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</div>

<div class="relative mt-36 pt-20 bg-transparent" style="margin-top: auto;">
  <div class="left-64 bottom-0">
    <div class="flex flex-col items-center">
      <pagination-controls id="listing_pagination1" maxSize="5" directionLinks="true"
        (pageChange)="onChangePage($event)" previousLabel="" nextLabel=""></pagination-controls>
    </div>
  </div>
</div>
