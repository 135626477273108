<div class="bg-white rounded-20 w-full max-w-479">
    <div class="flex flex-col text-center items-center mt-24 ml-60">
        <div mat-button mat-dialog-close
            class="ml-auto bg-gray5 rounded-40 p-14 cursor-pointer transform hover:scale-105 transition duration-300 mr-20">
            <img src="../assets/images/icons/VectorX.png" alt=""></div>
       <div class="mr-60">
           <div class="w-full flex justify-center items-center mb-16">
           <div class="w-42 bg-green100 h-42 rounded-40 flex justify-center items-center">
             <img src="../assets/images/icons/Vector14.png" alt=""
               class="w-20 transform hover:scale-105 transition duration-300"></div>
               </div>
        <div class="mb-8">
            <span class="text-20 font-bold text-gray700">{{data.text}}</span>
        </div>
        <div class="mb-60">
            <span class="text-12 font-semibold text-gray700"> {{data.additionalText}}</span>
        </div>
        </div>
    </div>
</div>