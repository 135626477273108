import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { User } from '../_types/_models';
import { UserService } from '../_services';
import { Router } from '@angular/router';
import { AppConfig, APP_CONFIG } from '../_types/_configs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  loading = false;
  users: User[];

  constructor(private userService: UserService, private http: HttpClient, private router: Router, @Inject(APP_CONFIG) private _appConfig: AppConfig) { }

  ngOnInit() {
    this.loading = true;

    // this.userService.getAll().pipe(first()).subscribe(users => {
    //     this.loading = false;
    //     this.users = users;
    // });
  }

  geeettttt() {
    return this.http.get<any>(`${this._appConfig.apiEndPoint}/policy/ping`)
      .pipe(map((response) => {
        return response;
      })).subscribe();
  }
}
