import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CargoCalculatorModel } from '../application/models/cargoCalculatorModel';
import { AppConfig, APP_CONFIG } from '../_types/_configs';
import { ApiResponse } from '../_types/_models/api-response';
import { CargoApplicationEditModel } from '../_types/_models/cargoApplicationModels';
import { PolicyFilterModel } from '../_types/_models/policyModel';

@Injectable({ providedIn: 'root' })
export class PolicyService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private _appConfig: AppConfig) { }

  calculate(model: CargoCalculatorModel) {
    return this.http.post<any>(`${this._appConfig.apiEndPoint}/Policy/Calculate`, model);
  }

  save(model: CargoApplicationEditModel) {
    return this.http.post<any>(`${this._appConfig.apiEndPoint}/Policy/save`, model);
  }

  getPolicies(model: PolicyFilterModel) {
    return this.http.post<any>(
      `${this._appConfig.apiEndPoint}/policy/getactivepolicy`,
      model
    );
  }

  getPoliciesAllDebet(){
    return this.http.get<any>(`${this._appConfig.apiEndPoint}/policy/getpoliciesalldebet`)
  }

  reviewPayment(policyIds){
    return this.http.post<any>
    (`${this._appConfig.apiEndPoint}/policy/reviewpayment`, policyIds);    
  }
  
  checkOverdue(){
    return this.http.get<any>(`${this._appConfig.apiEndPoint}/policy/checkoverdue`)
  }
  processPayment(transactionID: any){
    return this.http.get<any>(`${this._appConfig.apiEndPoint}/policy/ProcessPayment?transactionID=${encodeURIComponent(transactionID)}`);
  }
  
  resendPolicyPurchaseNotifications(policyId: number) {

    return this.http.post<ApiResponse<boolean>>(
      `${this._appConfig.apiEndPoint}/Policy/resendPolicyPurchaseNotifications`,
      policyId
    );
  }
}
