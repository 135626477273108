import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationSummaryDialogComponent } from '../application-summary-dialog/application-summary-dialog.component';
import { DeleteApplicationDialogComponent } from '../delete-application-dialog/delete-application-dialog.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { ApplicationService } from '../_services/application.service';
import {
  ApplicationFilterModel,
  ApplicationGridViewModel,
  CargoApplicationEditModel,
} from '../_types/_models/cargoApplicationModels';

@Component({
  selector: 'app-application-grid',
  templateUrl: './application-grid.component.html',
  styleUrls: ['./application-grid.component.css'],
})
export class ApplicationGridComponent implements OnInit {
  applicationFilterData: ApplicationFilterModel = {};

  data: ApplicationGridViewModel;
  page: number = 1;
  applicationData: CargoApplicationEditModel;

  constructor(
    public applicationService: ApplicationService,
    public dialog: MatDialog
  ) {}

  start;
  last;
  ngOnInit(): void {
    this.applicationFilterData.page = 1;
    this.getApplications(this.applicationFilterData);
  }

  delete(applicationID) {
    const dialogRef = this.dialog.open(DeleteApplicationDialogComponent, {
      width: '480px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.applicationService
          .deleteApplication(applicationID)
          .subscribe((response) => {
            if (response.value) {
              this.getApplications(this.applicationFilterData);
              const dialogRef = this.dialog.open(SuccessDialogComponent, {
                width: '350px',
                data: { text: 'განაცხადი წარმატებით წაიშალა' },
              });
            }
          });
      }
    });
  }

  onChangePage($event) {
    this.page = $event;
    this.applicationFilterData.page = $event;
    this.getApplications(this.applicationFilterData);
  }

  confirm(applicationID: number) {
    this.applicationService
      .getApplication(applicationID)
      .subscribe((result) => {
        this.applicationData = result.value;

        const dialogRef = this.dialog.open(ApplicationSummaryDialogComponent, {
          data: this.applicationData,
        });
      });
  }

  getApplications(filterModel: ApplicationFilterModel) {
    this.applicationService
      .getApplications(filterModel)
      .subscribe((response) => {
        this.data = response.value;
      });
  }
}
