export class PolicyInfoDialogModel {
  beneficiaryFullName: string;
  toDate: string;
  fromDate: string;
  policyNumber: string;
  containerTypeText : string;
  premium : number;
  containers : string[];
  id: number;
}
