import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { PolicyService } from '../_services/policy.service';
import { PaymentViewModel, PolicyAllDebetViewModel } from '../_types/_models/policyModel';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.css']
})
export class ActionButtonsComponent implements OnInit {

  @Output() getReportClicked: EventEmitter<any> = new EventEmitter();
  constructor(private policyService: PolicyService, private router: Router, public dialog: MatDialog) { }

  @Input() allDebetGel: number;
  @Input() policyIds : number[] = [];
  @Input() policy : PolicyAllDebetViewModel;
  paymentModel : PaymentViewModel;
  ngOnInit(): void {

  }
  getReport(){
    this.getReportClicked.emit();
  }

  reviewPayment(){
    this.policyService.reviewPayment(this.policy.policyIds).subscribe(
      (response) => {
        this.paymentModel = response.value;
        window.open(this.paymentModel.redirectUrl+this.paymentModel.transactionID, "_self");
      }
    );

  }

  checkOverdue(){
    this.policyService.checkOverdue().subscribe((response)=>{
      if(response.value){
        this.router.navigate(['/application']);
      }else{
        let errorText = "ახალი პოლისის შეძენა შეზღუდულია დავალიანების გამო, გთხოვ დაფარო პოლისებზე გადასახდელი პრემია"
        this.dialog.open(ErrorDialogComponent, {
          width: '350px',
          data: { additionalText: errorText},
        });
      }
    })
  }
}
