import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { PolicyService } from '../_services/policy.service';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {

  constructor(private route: ActivatedRoute, private policyService: PolicyService, public dialog: MatDialog, private router: Router) { }

  transactionId : any;
  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.transactionId = params.transactionId;      
    }
  );


  this.policyService.processPayment(this.transactionId).subscribe(
    (response) => {
      if(response.value){
        const dialogRefSucces = this.dialog.open(SuccessDialogComponent, {
          width: '350px',
          data: { text: 'გადახდა წარმატებით განხორციელდა' },
        });


        dialogRefSucces.afterClosed().subscribe((result) => {
          this.router.navigate(['/']);
        });

      }else{
        const dialogRefError = this.dialog.open(ErrorDialogComponent, {
          width: '350px',
          data: { text: 'დაფიქსირდა შეცდომა გტხოვთ სცადოთ მოგვიანებით'},
        });
        dialogRefError.afterClosed().subscribe((result)=>{
          this.router.navigate(['/']);
        })
        
      }
    }
  );

  

  }

}
