<div class="bg-white rounded-20 w-full">
  <div class="flex flex-col text-center items-center mt-24 ml-60">
    <div mat-button mat-dialog-close
      class="ml-auto bg-gray5 rounded-40 p-14 cursor-pointer transform hover:scale-105 transition duration-300 mr-20">
      <img src="../assets/images/icons/VectorX.png" alt="">
    </div>
    <div class="mr-60">
      <div class="w-full flex justify-center items-center mb-16">
        <div class="w-42 bg-gray5 h-42 rounded-40 flex justify-center items-center mb-16">
          <img src="../assets/images/icons/Vector22.png" alt=""
            class="w-20 transform hover:scale-105 transition duration-300">
        </div>
      </div>
      <div>
        <p class="text-20 MYRIADGEOMTAVRULIBOLD text-gray700 mb-36">ნამდვილად გსურს განაცხადის <br> წაშლა?</p>
      </div>
      <div class="flex mb-28">
        <button class="bg-white200 relative group rounded-20 before:rounded-20 hover:rounded-20 items-center flex before:absolute
         before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top before:transition before:duration-300
         hover:before:scale-x-100 hover:before:origin-buttom justify-center cursor-pointer">
          <span class="text-gray700 text-16 MYRIADGEOMTAVRULIBOLD py-20 px-32 relative" mat-button mat-dialog-close>გაუქმება</span>
        </button>
        <button class="bg-red300 hover:bg-opacity-75 flex items-center rounded-20 ml-auto">
          <span class="text-white text-16 MYRIADGEOMTAVRULIBOLD py-20 px-32" [mat-dialog-close]="true">წაშლა</span></button>
      </div>
    </div>
  </div>
</div>
