<div class="bg-gray5 w-full">
  <section class="flex justify-center w-full">
    <div class="grid grid-cols-8 gap-4 p-40 gap-10 items-center">
      <span class="text-8 uppercase">font</span>
      <span class="text-10 uppercase ">font</span>
      <span class="text-12 uppercase">font</span>
      <span class="text-14 uppercase">font</span>
      <span class="text-16 uppercase">font</span>
      <span class="text-18 uppercase">font</span>
      <span class="text-24 uppercase">font</span>
      <span class="text-36 uppercase">font</span>
    </div>
  </section>
  <!-- font-size /. -->

  <section class="flex justify-center w-full">
    <div class="container flex justify-center p-40 gap-10">
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector1.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector2.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector3.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector4.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector5.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector6.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector7.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector8.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector9.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector10.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector11.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector12.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector13.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector14.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector15.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector16.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector17.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector18.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector19.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector20.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector21.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/Vector22.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/VectorX.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/arrow.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/calendar.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/exit.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/settings.png" alt="" class="">
      </div>
      <div class="p-10 items-center flex justify-center bg-black bg-opacity-10 hover:bg-opacity-5">
        <img src="../assets/images/icons/trash.png" alt="" class="">
      </div>
    </div>
  </section>
  <!-- icons /. -->

  <section class="flex justify-center w-full">
    <div class="container flex justify-center gap-10 p-40">
      <div
        class="bg-white w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-white100 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-white200 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-purple w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray1 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray5 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray10 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray50 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray100 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray200 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray300 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray400 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray500 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray600 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-blue600 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-blue700 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray700 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-black w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray900 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-gray800 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-blue400 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-blue w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-blue100 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-blue200 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-blue300 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-blue500 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-blue800 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-red w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-red50 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-red100 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-red300 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-red200 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-pink w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-pink100 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-pink200 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-pink300 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-green w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-green100 w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>
      <div
        class="bg-yellow w-30 h-30 cursor-pointer transform hover:scale-125 transition duration-300 border border-black">
      </div>



    </div>
  </section>
  <!-- colors /. -->

  <section class="flex justify-center w-full">
    <div class="grid grid-cols-4 gap-4 p-20">
      <button class="relative group bg-pink py-22 px-54 rounded-20  before:rounded-20 hover:rounded-20 items-center flex
       before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center
        cursor-pointer">
        <span class="font-bold text-16 text-white text-center relative">ავტორიზაცია</span>
      </button>

      <button class="relative group bg-pink py-22 px-54 rounded-20  before:rounded-20 hover:rounded-20 items-center flex
       before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center
        cursor-pointer">
        <span class="text-white text-16 font-bold relative">გაფილტვრა</span>
      </button>

      <button class="relative group bg-pink py-22 px-54 rounded-20  before:rounded-20 hover:rounded-20 items-center flex
       before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center
        cursor-pointer">
        <span class="text-white font-bold text-14 relative">გადახდა</span>
      </button>

      <button class="relative group bg-pink py-22 px-54 rounded-20  before:rounded-20 hover:rounded-20 items-center flex
       before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center
        cursor-pointer">
        <img src="../assets/images/icons/Vector21.png" alt="" class="mr-12 relative">
        <span class="text-white text-16 font-bold relative">დადასტურება</span>
      </button>

      <button class="bg-white200 px-40 py-18 relative group rounded-20  before:rounded-20 hover:rounded-20 items-center flex
       before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center
        cursor-pointer">
        <img src="../assets/images/icons/Vector16.png" alt="" class="mr-12 relative">
        <span class="text-16 font-bold text-gray700 relative">საბეჭდი ფორმა</span>
      </button>

      <button class="bg-white200 px-40 text-left py-10 relative group rounded-20  before:rounded-20 hover:rounded-20 items-center flex
       before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center
        cursor-pointer">
        <img src="../assets/images/icons/Vector15.png" alt="" class="mr-12 relative">
        <span class="text-16 font-bold text-gray700 relative">საზღვაო ხაზთან <br> გაგზავნა</span>
      </button>

      <button class="bg-white200 py-22 px-54 relative group rounded-20  before:rounded-20 hover:rounded-20 items-center flex
       before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center
        cursor-pointer">
        <img src="../assets/images/icons/Vector20.png" alt="" class="mr-12 relative">
        <span class="text-gray700 text-16 font-bold relative">რედაქტირება</span>
      </button>

      <button class="bg-white200 relative group rounded-20  before:rounded-20 hover:rounded-20 items-center flex
       before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center
        cursor-pointer">
        <span class="text-gray700 text-16 font-bold py-20 px-32 relative">გაუქმება</span>
      </button>

      <button class="bg-red300 rounded-20 items-center flex justify-center cursor-pointer hover:opacity-75">
        <span class="text-white text-16 font-bold py-20 px-32">წაშლა</span>
      </button>

      <button type="button" class="text-white bg-gradient-to-r from-blue100 via-blue200 to-blue300 hover:bg-gradient-to-br focus:ring-4
      focus:outline-none focus:ring-blue400 dark:focus:ring-blue500 font-medium rounded-lg text-sm px-5 py-2.5
      text-center mr-2 mb-2">
        <span class="text-white text-16 font-bold py-20 px-32">type</span>
      </button>
      <button type="button" class="text-white bg-gradient-to-r from-gray10 via-blue200 to-gray10 hover:bg-gradient-to-br focus:ring-4
      focus:outline-none focus:ring-gray50 dark:focus:ring-gray100 font-medium rounded-lg text-sm px-5 py-2.5
      text-center mr-2 mb-2">
        <span class="text-white text-16 font-bold py-20 px-32">type</span>
      </button>
      <button type="button" class="text-white bg-gradient-to-r from-pink100 via-pink300 to-red200 hover:bg-gradient-to-br focus:ring-4
      focus:outline-none focus:ring-pink dark:focus:ring-red200 font-medium rounded-lg text-sm px-5 py-2.5
      text-center mr-2 mb-2">
        <span class="text-white text-16 font-bold py-20 px-32">type</span>
      </button>
    </div>
  </section>
  <!-- btn /. -->

  <section class="flex justify-center w-full">
    <div class="bg-white my-49 rounded-20 w-full max-w-479">
      <div class="flex flex-col items-center justify-center">
        <div class="flex gap-20 my-16">
          <label class="inline-flex items-center">
            <div class="bg-pink border rounded-8 border-pink w-24 h-24 flex flex-shrink-0 justify-center items-center">
              <input type="checkbox" class="opacity-0 absolute">
              <img src="../assets/images/icons/Vector11.png" alt="" class="fill-current hidden pointer-events-none">
            </div>
          </label>

          <label class="inline-flex items-center">
            <div
              class="bg-white border rounded-8 border-gray5 w-24 h-24 flex flex-shrink-0 justify-center items-center">
              <input type="checkbox" class="opacity-0 absolute">
              <img src="../assets/images/icons/Vector11.png" alt="" class="fill-current hidden pointer-events-none">
            </div>
          </label>

          <label class="inline-flex items-center">
            <div class="bg-white border rounded-8 border-pink w-24 h-24 flex flex-shrink-0 justify-center items-center">
              <input type="checkbox" class="opacity-0 absolute">
              <i class="bg-pink w-9 h-9 rounded-2 block"></i>
            </div>
          </label>
        </div>
      </div>
    </div>
  </section>
  <!-- checkbox /.  -->

  <section class="flex justify-center w-full">
    <button class="mb-49 bg-pink rounded-8 py-12 px-16 flex items-center">
      <span
        class="rounded-40 bg-black bg-opacity-10 w-24 h-24 text-14 font-bold text-center items-center justify-center flex text-white mr-8">3</span>
      <span class="text-white text-12 font-bold">ფილტრი</span>
      <img src="../assets/images/icons/Vector13.png" alt=""
        class="w-12 transform hover:scale-105 transition duration-300 ml-30">
    </button>
  </section>

  <section class="flex justify-center w-full">
    <div class="flex gap-20 ">
      <button class="bg-blue400 rounded-20 py-8 flex items-center pr-16 pl-8">
        <span
          class="rounded-40 bg-blue500 w-24 h-24 text-12 font-bold text-center items-center justify-center flex text-white">3</span>
        <span class="text-gray700 text-12 font-bold ml-8">მაცივარი</span>
      </button>
      <button class="bg-blue400 rounded-20 py-8 flex items-center pr-16 pl-8">
        <span
          class="rounded-40 bg-blue500 w-24 h-24 text-12 font-bold text-center items-center justify-center flex text-white">1</span>
        <span class="text-gray700 text-12 font-bold ml-8">სტანდარტული</span>
      </button>
    </div>
  </section>
  <!-- btn /. -->

  <section class="flex justify-center w-full">
    <div class="flex gap-20 items-center">
      <div
        class="bg-white rounded-12 py-24 my-49 max-w-449 border border-blue600 relative items-center flex-direction flex flex-col">
        <div class="absolute rounded-3 border-l border-t border-blue600 w-14 h-14 -top-8 rotate-45 bg-white"></div>
        <div class="mb-16 flex justify-center">
          <span class="font-semibold text-14 text-gray700">დამატებული კონტეინერები</span>
        </div>

        <div class="mx-30">
          <div class="grid grid-cols-9 w-full gap-10">
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                k
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                D
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                o
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                2
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                5
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                4
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                4
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                1
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                v
              </label>
            </div>
          </div>
        </div>

        <div class="mx-30 my-16">
          <div class="grid grid-cols-9 w-full gap-10">
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                k
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                D
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                o
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                2
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                5
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                4
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                4
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                1
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                v
              </label>
            </div>
          </div>
        </div>

        <div class="mx-30">
          <div class="grid grid-cols-9 w-full gap-10">
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                k
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                D
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                o
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                2
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                5
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                4
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                4
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                1
              </label>
            </div>
            <div
              class="rounded-12 w-34 h-34 flex justify-center items-center border border-gray5 hover:bg-gray5 cursor-pointer">
              <label class="uppercase text-14 font-bold text-blue text-opacity-80">
                v
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-white rounded-12 shadow-xl mb-49 w-143 border border-blue600 relative items-center flex-direction flex flex-col ">
        <div class="absolute rounded-3 border-l border-t border-blue600 w-14 h-14 -top-8 rotate-45 bg-white"></div>
        <div class="flex justify-center flex-col w-full">
          <div class="hover:bg-white200 px-20 pt-14  pb-12 border-b border-black border-opacity-5">
            <span class="font-semibold text-12 text-gray700">დადასტურება</span>
          </div>
          <div class="hover:bg-white200 px-20 py-12 border-b border-black border-opacity-5">
            <span class="font-semibold text-12 text-gray700">რედაქტირება</span>
          </div>
          <div class="hover:bg-white200 px-20 py-12">
            <span class="font-semibold text-12 text-red300">წაშლა</span>
          </div>
        </div>


      </div>
    </div>
  </section>
  <!-- Added containers /. -->

  <section class="flex justify-center w-full">
    <div class="flex flex-col items-center">
      <div class="flex">

        <div
          class="bg-gray400 hover:bg-gray800 cursor-pointer flex h-34 items-center justify-center mr-12 rounded-8 w-34">
          <img src="../assets/images/icons/Vector8.png" alt="" class="">
        </div>

        <div class="flex h-34">
          <div
            class="border border-blue cursor-pointer flex font-bold h-34 items-center justify-center mr-12 rounded-8 text-14 text-blue w-34 bg-white">
            1</div>
          <div
            class="border border-gray300 cursor-pointer flex font-bold h-34 items-center justify-center mr-12 rounded-8 text-14 text-blue text-opacity-40 w-34 bg-white">
            2</div>
          <div
            class="border border-gray300 cursor-pointer flex font-bold h-34 items-center justify-center mr-12 rounded-8 text-14 text-blue text-opacity-40 w-34 bg-white">
            3</div>
          <div class="md:flex justify-center items-end hidden cursor-pointer rounded-8 text-black font-bold mr-12">
            ...</div>
          <div
            class="border border-gray300 cursor-pointer flex font-bold h-34 items-center justify-center mr-12 rounded-8 text-14 text-blue text-opacity-40 w-34 bg-white">
            12</div>
        </div>

        <div
          class="bg-gray400 hover:bg-gray800 cursor-pointer flex h-34 items-center justify-center mr-12 rounded-8 w-34">
          <img src="../assets/images/icons/Vector9.png" alt="" class="">
        </div>

        <div
          class="bg-gray400 hover:bg-gray800 cursor-pointer flex h-34 items-center justify-center mr-12 rounded-8 w-34">
          <img src="../assets/images/icons/Vector10.png" alt="" class="">
        </div>

      </div>
    </div>
  </section>
  <!-- Pagination /. -->

  <section class="flex justify-center w-full">
    <div class="bg-white100 px-30 py-30 flex max-w-860 w-full rounded-20 my-49">
      <div class="w-42 h-42 rounded-40 bg-pink100 items-center justify-center flex">
        <span class="w-20 h-20 rounded-40 flex text-center justify-center bg-pink text-white font-bold text-12">3</span>
      </div>
      <div class="flex-col flex ml-30">
        <span class="text-16 text-gray700 font-bold">გადასახდელი პოლისები</span>
        <span class="text-14 font-semibold text-gray700">თქვენ გაქვთ გადასახდელი 3 პოლისი</span>
      </div>
      <div class="ml-auto ">
        <button class="bg-pink py-12 px-20 rounded-12
        relative group before:rounded-12 hover:rounded-12 
        before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom cursor-pointer">
          <span class="text-white font-bold text-14 relative">გადახდა</span>
        </button>
      </div>
    </div>
  </section>
  <!-- Policies payable / . -->

  <section class="flex justify-center w-full">
    <div class="bg-pink200 px-20 py-16 flex max-w-540 w-full rounded-20 mb-49 border border-pink300">
      <div>
        <span class="text-gray700 text-14 font-semibold">პოლისის პრემია</span>
      </div>
      <div class="ml-auto">
        <span class="text-14 text-pink font-bold mr-2">123.50</span>
        <span class="text-14 text-pink font-bold">₾</span>
      </div>
    </div>
  </section>
  <!-- Policy Award /. -->

  <section class="flex justify-center w-full">
    <div class="bg-white mb-49 rounded-20 w-full max-w-860">
      <div class="flex flex-col text-center items-center p-10">
        <div class="w-42 bg-red200 h-42 rounded-40 flex justify-center items-center mb-16">
          <img src="../assets/images/icons/Vector12.png" alt=""
            class="w-20 transform hover:scale-105 transition duration-300">
        </div>
        <div class="mb-8">
          <p class="text-16 font-bold text-gray700">ინფორმაცია ვერ მოიძებნა</p>
        </div>
        <div>
          <p class="text-12 font-semibold text-gray700">გთხოვთ დარწმუნდეთ თქვენ მიერ შეყვანილი კოინტეინერის
            <br> ნომრის სისწორეში</p>
        </div>
      </div>
    </div>
  </section>
  <!--Information not found /. -->

  <section class="flex justify-center w-full">
    <div class="flex gap-20 w-full justify-center mb-49">
      <div class="bg-white rounded-20 w-full max-w-479">
        <div class="flex flex-col text-center items-center mt-24">
          <div
            class="ml-auto bg-gray5 rounded-40 p-14 cursor-pointer transform hover:scale-105 transition duration-300 mr-20">
            <img src="../assets/images/icons/VectorX.png" alt="">
          </div>
          <div class="w-42 bg-red200 h-42 rounded-40 flex justify-center items-center mb-16">
            <img src="../assets/images/icons/Vector12.png" alt=""
              class="w-20 transform hover:scale-105 transition duration-300">
          </div>
          <div class="mb-8">
            <p class="text-20 MYRIADGEOMTAVRULIBOLD text-gray700">მოქმედება წარუმატებელია</p>
          </div>
          <div>
            <p class="text-12 font-semibold text-gray700 mb-60">დამხმარე ტექსტი, რომელიც დაემხარება <br>
              მომხმარებელს შედეგის გააზრებაში </p>
          </div>
        </div>
      </div>
      <div class="bg-white rounded-20 w-full max-w-479">
        <div class="flex flex-col text-center items-center mt-24">
          <div
            class="ml-auto bg-gray5 rounded-40 p-14 cursor-pointer transform hover:scale-105 transition duration-300 mr-20">
            <img src="../assets/images/icons/VectorX.png" alt="">
          </div>
          <div class="w-42 bg-green100 h-42 rounded-40 flex justify-center items-center mb-16">
            <img src="../assets/images/icons/Vector14.png" alt=""
              class="w-20 transform hover:scale-105 transition duration-300">
          </div>
          <div class="mb-8">
            <p class="text-20 MYRIADGEOMTAVRULIBOLD text-gray700">მოქმედება წარმატებულია</p>
          </div>
          <div>
            <p class="text-12 font-semibold text-gray700 mb-60">დამხმარე ტექსტი, რომელიც დაემხარება <br>
              მომხმარებელს შედეგის გააზრებაში </p>
          </div>
        </div>
      </div>
      <div class="bg-white rounded-20 w-full max-w-479">
        <div class="flex flex-col text-center items-center mt-24">
          <div
            class="ml-auto bg-gray5 rounded-40 p-14 cursor-pointer transform hover:scale-105 transition duration-300 mr-20">
            <img src="../assets/images/icons/VectorX.png" alt="">
          </div>
          <div class="w-42 bg-gray5 h-42 rounded-40 flex justify-center items-center mb-16">
            <img src="../assets/images/icons/Vector22.png" alt=""
              class="w-20 transform hover:scale-105 transition duration-300">
          </div>
          <div>
            <p class="text-20 MYRIADGEOMTAVRULIBOLD text-gray700 mb-36">ნამდვილად გსურთ განაცხადის<br>
              წაშლა?</p>
          </div>
          <div class="flex gap-24 mb-28">
            <button class="bg-white200 relative group rounded-20  before:rounded-20 hover:rounded-20 items-center flex
       before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center
        cursor-pointer">
              <span class="text-gray700 text-16 font-bold py-20 px-32 relative">გაუქმება</span>
            </button>
            <button class="bg-red300 hover:bg-opacity-75 flex items-center rounded-20">
              <span class="text-white text-16 font-bold py-20 px-32">წაშლა</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- The actions /. -->
</div>
