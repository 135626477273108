import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationService } from '../_services/application.service';
import { CargoApplicationEditModel } from '../_types/_models/cargoApplicationModels';

@Injectable({ providedIn: 'root' })
export class ApplicationResolver implements Resolve<CargoApplicationEditModel> {
  constructor(private service: ApplicationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<CargoApplicationEditModel>
    | Promise<CargoApplicationEditModel>
    | CargoApplicationEditModel {
    if (route.paramMap.get('id'))
      return this.service.getApplication(route.paramMap.get('id'));
  }
}
