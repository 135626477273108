import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorInterceptor, JwtInterceptor } from './_helpers';
import { TokenStoreService } from './_services/token-store.service';
import { UtilsService } from './_services/utils.service';
import { BrowserStorageService } from './_services/browser-storage.service';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { GuidelineComponent } from './guideline/guideline.component';

//angular material

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { CalculatorSummerDialogComponent } from './calculator-summer-dialog/calculator-summer-dialog.component';
import { ApplicationGridComponent } from './application-grid/application-grid.component';
import { NgxPaginationModule } from 'ngx-pagination'
import { DeleteApplicationDialogComponent } from './delete-application-dialog/delete-application-dialog.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { ApplicationComponent } from './application/application.component';
import { ApplicationSummaryDialogComponent } from './application-summary-dialog/application-summary-dialog.component';
import { PolicyInfoDialogComponent } from './policy-info-dialog/policy-info-dialog.component';
import { PolicyGridComponent } from './policy-grid/policy-grid.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { AppInitializer } from './_helpers/app.initializer';
import { appConfig, APP_CONFIG } from './_types/_configs';
import { PolicyFilterModalComponent } from './policy-filter-modal/policy-filter-modal.component';
import { GridComponent } from './grid/grid.component';
import { TransactionComponent } from './transaction/transaction.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    GuidelineComponent,
    ApplicationComponent,
    CalculatorSummerDialogComponent,
    ApplicationGridComponent,
    DeleteApplicationDialogComponent,
    SuccessDialogComponent,
    ApplicationSummaryDialogComponent,
    PolicyInfoDialogComponent,
    PolicyGridComponent,
    ErrorDialogComponent,
    ActionButtonsComponent,
    PolicyFilterModalComponent,
    GridComponent,
    TransactionComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule, 
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatMenuModule,
    NgxPaginationModule,
    NgHttpLoaderModule.forRoot(),
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializer: AppInitializer) => () => { return appInitializer.init() },
      deps: [AppInitializer],
      multi: true,
    },
    { provide: APP_CONFIG, useValue: appConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
