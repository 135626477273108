import { saveAs } from 'file-saver';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Services
import { UtilsService } from './utils.service';
// Types
import { ApiResponse } from '../_types/_models/api-response';
import { DownloadViewModel } from '../_types/_models/attachment.model';
import { PolicyFilterModel } from '../_types/_models/policyModel';
import { AppConfig, APP_CONFIG } from '../_types/_configs';

@Injectable({ providedIn: 'root' })
export class CommonService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private _appConfig: AppConfig) {
  }

  downloadFile(url: string) {

    this.http.get<ApiResponse<DownloadViewModel>>(url).subscribe(response => {

      if (!response.hasError && response.value) {

        if (window['androidJs']) {
          window['androidJs'].downloadBlob(response.value.base64String);
        } else {
          var blob = UtilsService.base64ToBlob(response.value.base64String, response.value.mimeType, 0);
          saveAs(blob, response.value.title);
        }
      }
    });
  }

  downloadExcel(url: string, model: any) {

    this.http.post<ApiResponse<DownloadViewModel>>(url, model).subscribe(response => {

      if (!response.hasError && response.value) {

        if (window['androidJs']) {
          window['androidJs'].downloadBlob(response.value.base64String);
        } else {
          var blob = UtilsService.base64ToBlob(response.value.base64String, response.value.mimeType, 0);
          saveAs(blob, response.value.title);
        }
      }
    });
  }

  getFile(url: string) {
    return this.http.get<ApiResponse<DownloadViewModel>>(url);
  }

  print(policyNumber: string) {
    return this.downloadFile(`${this._appConfig.apiEndPoint}/Policy/print?policyNumber=${encodeURIComponent(policyNumber)}`);
  }

  printPolicyPdfById(policyID: number) {
    return this.downloadFile(`${this._appConfig.apiEndPoint}/Policy/printpolicypdfbyid?policyId=${encodeURIComponent(policyID)}`);
  }

  getReport(policyFilterData: PolicyFilterModel) {

    return this.downloadExcel(`${this._appConfig.apiEndPoint}/Policy/getreport`, policyFilterData);
  }
}
