import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../_types/_models';
import { AppConfig, APP_CONFIG } from '../_types/_configs';



@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG)private _appConfig: AppConfig) { }

  getAll() {
    return this.http.get<User[]>(`${this._appConfig.apiEndPoint}/users`);
  }
}
