<div class="w-full px-30 pt-20 pb-14 flex items-center" *ngIf="!searchOpened">
  <button (click)="openFilter()" *ngIf=" !filtered "
    class="flex inline-block justify-center py-10 px-16 border border-gray300 border-opacity-30 rounded-12 hover:border-gray500 transition duration-150 ease-in-out">
    <img src="../assets/images/icons/settings.png" alt="" class="">
    <span class="pl-8 text-gray700 NotoSansGeorgianSemiBold text-12">ფილტრი</span>
  </button>

  <button class="bg-pink rounded-8 py-12 px-16 flex items-center" (click)="openFilter()" *ngIf="filtered"><span
      class="rounded-40 bg-black bg-opacity-10 w-24 h-24 text-14 font-bold text-center items-center justify-center flex text-white mr-8">{{data.count}}</span><span
      class="text-white text-12 font-bold">ფილტრი</span>
    <img src="../assets/images/icons/Vector13.png" alt=""
      class="w-12 transform hover:scale-105 transition duration-300 ml-30" (click)="clearFilter()"></button>

  <button
    class="ml-auto flex items-center inline-block justify-center py-10 px-16 border border-gray300 border-opacity-30 rounded-12 hover:border-gray500 transition duration-150 ease-in-out"
    *ngIf="!searchOpened" (click)="openSearch()">
    <img src="../assets/images/icons/Vector5.png" alt="" class="mr-5">
    <span class="text-gray700 NotoSansGeorgianSemiBold text-12">ძებნა</span>
  </button>
</div>
<!--search bar  -->
<form (ngSubmit)="search()" @flyInOut *ngIf="searchOpened" class="mt-25 flex items-center mx-30 pb-14">
  <div class="relative w-full items-center flex">
    <div class="flex absolute inset-y-0 left-0 items-center pointer-events-none pl-18">
      <svg class="w-14 h-14" fill="#595959" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clip-rule="evenodd"></path>
      </svg>
    </div>
    <div class="absolute bg-gray5 cursor-pointer items-center p-8 right-0 rounded-40 mr-10" (click)="closeSearch()">
      <img src="../assets/images/icons/VectorX.png" alt="">
    </div>
    <input type="text" #searchInput class="border border-black border-opacity-5 text-gray700 text-11 rounded-12 block w-full h-36 px-40 
    NotoSansGeorgianMedium focus:outline-none" [(ngModel)]="searchText"
      placeholder="ჩაწერე საძიებო სიტყვა">
  </div>
</form>
<!--search bar  -->

<div class="md:max-w-full md:mx-30 h-470"  *ngIf="this.data?.policies && this.data?.policies.length > 0  ">
  <div class="flex flex-col">
    <div class="min-w-full align-middle">
      <div class="">
        <table class="w-full whitespace-nowrap">

          <thead class="border-b border-black border-opacity-5">
            <tr>
              <th>
                <div
                  class="bg-white border rounded-8 border-blue700 w-24 h-24 flex flex-shrink-0 justify-center items-center mb-16" [ngClass]="(isAllChecked)? 'bg-pink':''">
                  <input type="checkbox" class="opacity-0 absolute"  (change)="checkAll($event.target.checked)">
                  <img src="../assets/images/icons/Vector11.png" alt="" class="fill-current hidden pointer-events-none">
                </div>
              </th>
              <th scope="col" class="">
                <div class="flex items-center cursor-pointer group-hover pb-16">
                  <span
                    class="text-10 md:text-12 NotoSansGeorgianMedium text-blue300 text-opacity-70 group-hover:text-blue">საზღვაო
                    ხაზი</span>
                </div>
              </th>
              <th scope="col" class="">
                <div class="flex items-center cursor-pointer group-hover pb-16">
                  <span
                    class="text-10 md:text-12 NotoSansGeorgianMedium text-blue300 text-opacity-70
                    ml-15">კონტეინერები</span>
                </div>
              </th>
              <th scope="col" class="">
                <div class="flex items-center cursor-pointer group-hover pb-16">
                  <span
                    class="text-10 md:text-12 NotoSansGeorgianMedium text-blue300 text-opacity-70 group-hover:text-blue">დავალიანება</span>
                </div>
              </th>
              <th scope="col" class="">
                   <div class="flex items-center cursor-pointer group-hover pb-16">
                     <span
                       class="text-10 md:text-12 NotoSansGeorgianMedium text-blue300 text-opacity-70 group-hover:text-blue">დეტალები</span>
                   </div>
              </th>
            </tr>
          </thead>

          <tbody class="">
            <tr *ngFor="let item of this.data?.policies | paginate: {
                         id: 'listing_pagination2',
                         itemsPerPage: 8,
                         currentPage: page,
                         totalItems: this.data?.count}"
              class="h-54 items-center border-b border-black border-opacity-5">
              <td class=""(click)="checkedItem(item.id)">
                <div
                  class="bg-white border rounded-8 border-blue700 w-24 h-24 flex flex-shrink-0 justify-center items-center" [ngClass]="(item.isChecked)? 'bg-pink':''">
                  <input type="checkbox" class="opacity-0 absolute" [checked]="(item.isChecked)"  >
                  <img src="../assets/images/icons/Vector11.png" alt="" class="fill-current hidden pointer-events-none">
                </div>
              </td>
              <td class="">
                <div class="flex">
                  <span class="flex justify-center items-center pr-12">
                    <i class="w-8 h-8 bg-green rounded-40 -mt-4"></i>
                  </span>
                  <span class="md:text-12 text-10 NotoSansGeorgianSemiBold text-gray700">
                    {{ item.beneficiaryName }}
                  </span>
                </div>
              </td>
              <td>
                <button [matMenuTriggerFor]="menuContainer"
                  class="bg-blue400 rounded-20 py-10 flex items-center pr-16 pl-8">
                  <span
                    class="rounded-40 bg-blue500 w-20 h-20 text-center items-center text-white md:text-12 text-10 font-bold justify-center flex">{{item.containersCount}}</span>
                  <span class="text-gray700 text-10 md:text-12 font-semibold ml-8">{{ item.containerTypeText }}</span>
                </button>

                <mat-menu #menuContainer="matMenu">
                  <div class="flex justify-center flex-col w-full min-w-426">
                    <div
                      class="absolute rounded-3 border-l border-t border-blue600 w-14 h-14 -top-7 left-10 rotate-45 bg-white">
                    </div>
                    <div class="flex items-center px-16 mb-16">
                      <span class="NotoSansGeorgianSemiBold text-14 text-gray700">კონტეინერის ნომრები</span>
                      <span class="ml-auto">
                        <div routerlink="/" class="bg-gray5 rounded-40 p-11 cursor-pointer">
                          <img src="../assets/images/icons/VectorX.png" alt="">
                        </div>
                      </span>
                    </div>
                    <div class="w-full">
                      <div class="flex pl-40 pr-12 py-8 text-left" *ngFor="let item of item.containers.split(',')">
                        <span class="uppercase tracking-15 text-14 font-bold text-blue text-opacity-80">
                          {{item}}
                        </span>
                      </div>
                    </div>
                  </div>
                </mat-menu>

              </td>
              <td class="text-12 font-bold text-gray700 items-center">
                <span> {{ item.allDebet }} </span>
                <span>$</span>
              </td>
              <td class="w-30 h-30 pl-20">
                <button type="button" [matMenuTriggerFor]="menu">
                  <div class="w-30 h-30 rounded-40 flex items-center justify-center">
                    <img src="../assets/images/icons/tu.png" alt="">
                  </div>
                </button>
                <mat-menu #menu="matMenu">
                  <div class="flex justify-center flex-col w-full">
                    <div
                      class="absolute rounded-3 border-l border-t border-blue600 w-14 h-14 -top-7 left-10 rotate-45 bg-white">
                    </div>
                    <div class="hover:bg-white200 px-20 py-12 cursor-pointer" (click)="openPolicyIfo(item.id)">
                      <span class="NotoSansGeorgianSemiBold text-12 text-gray700">დეტალები</span>
                    </div>
                    <!-- <div class="hover:bg-white200 px-20 py-12 cursor-pointer" (click)="printPolicyPdfById(item.id)">
                      <span class="NotoSansGeorgianSemiBold text-12 text-gray700">ინვოისის გადმოწერა</span>
                    </div> -->
                  </div>
                </mat-menu>
              </td>

            </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</div>

<div class="bg-white mb-49 rounded-20 w-full max-w-860" *ngIf="(!this.data?.policies || this.data?.policies.length==0 )&&(filtered||filteredWithSearch)">
  <div class="flex flex-col text-center items-center p-10">
    <div class="w-42 bg-red200 h-42 rounded-40 flex justify-center items-center mb-16">
      <img src="../assets/images/icons/Vector12.png" alt="" class="w-20 transform hover:scale-105 transition duration-300"></div>
    <div class="mb-8">
      <p class="text-16 font-bold text-gray700">აქტიური პოლისი ვერ მოიძებნა</p>
    </div>
    <div>
      <p class="text-12 font-semibold text-gray700">გთხოვთ დარწმუნდეთ თქვენ მიერ შეყვანილი ინფორმაციის სისწორეში</p>
    </div>
  </div>
</div>

<div class="relative mt-36 pt-20 bg-transparent" style="margin-top: auto;" *ngIf="this.data?.policies && this.data?.policies.length > 0  ">
  <div class="left-64 bottom-0">
    <div class="flex flex-col items-center">
      <pagination-controls id="listing_pagination2" maxSize="5" directionLinks="true"
        (pageChange)="onChangePage($event)" previousLabel="" nextLabel=""></pagination-controls>
    </div>
  </div>
</div>