 <nav class="flex items-center justify-center bg-white w-full" *ngIf="currentUser">
   <div class="max-w-1300 w-full flex items-center py-20 px-20">
     <div class="flex items-center flex-no-shrink text-white">
     </div>
     <a routerLink="/" class="flex items-center flex-no-shrink text-white">
       <img src="../assets/images/icons/logo.png" alt="" class="">
     </a>

     <div class="ml-auto">
       <a href="#" class="flex inline-block justify-center items-center px-18 py-16 border border-gray900 border-opacity-30 rounded-12 text-14
        text-gray900 text-opacity-80 font-semibold" (click)="logout()">
         <img src="../assets/images/icons/exit.png" alt="" class="w-16 h-16 mr-10">გასვლა</a>
     </div>
   </div>
 </nav>

 <router-outlet></router-outlet>

<ng-http-loader
[backdrop]="true"
[backgroundColor]="'#FE2E64'"
[spinner]="spinnerStyle.skThreeBounce"
>
</ng-http-loader>
 <!-- <app-guideline></app-guideline> -->