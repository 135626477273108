import { CargoCalculatorModel } from "src/app/application/models/cargoCalculatorModel";
import { CargoCalculatorViewModel } from "src/app/application/models/cargoCalculatorViewModel";

 

export class CargoApplicationEditModel{
   public toDate: Date;
   public fromDate: Date;
   public containerTypeID : number;
   public beneficiaryID : number;
   public premium: number;
   public resultHash: string;
   public applicationID?: number;
   public risk: number;
   public containers : string []
 
    constructor() { 
        
    }
} 

export interface CargoApplicationSummerModel{
    calculatorEdit : CargoCalculatorModel,
    calculatorView : CargoCalculatorViewModel
}

export interface ApplicationFilterModel{
    page?:number;
    startDate?: Date;
    endDate? :Date;
}

export interface ApplicationRowViewModel{
    applicationID : number;
    beneficiaryFullName : number;
    premium : number;
    containers : string[];
    containerTypeText: string;
    containersCount: number;
}

export interface ApplicationGridViewModel{
    count : number;
    applications : ApplicationRowViewModel[];
}