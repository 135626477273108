export class PolicyFilterModel{
    page?:number;
    startDate?: Date;
    endDate?: Date;
    searchText:string;
    ContainerTypes: number[]=[];
    cleared:boolean;
    getAll:boolean = false;
}

export interface PolicyRowViewModel{
    id : number;
    productID : number;
    policyNumber : string;
    policyHolderID : number;
    containerTypeID : number,
    containerTypeText : string,
    containers : string,
    containersCount: number,
    holderFirstName : string,
    holderLastName : string,
    allDebet : number,
    allDebetGel: number,
    beneficiaryName : string,
    fromDate : Date,
    toDate: Date,
    premiumGel:number,
    premiumUsd: number,
    isChecked: boolean
}

export interface PolicyGridViewModel{
    count : number;
    policies : PolicyRowViewModel[];
}

export interface PolicyAllDebetViewModel{
    allDebet? : number,
    policyIds?: number[],
    count?: number

}

export interface PaymentViewModel{
    transactionID? :number;
    redirectUrl? :  string;
}