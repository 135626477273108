import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  constructor() {}

  public static deepClone(source) {
    return JSON.parse(JSON.stringify(source));
  }

  public static removeDuplicates(arr: any[]) {
    let unique = arr.filter(function (value, index, self) {
      return index == self.indexOf(value);
    });

    return unique;
  }

  public static logInvalidFormControls(group: FormGroup | FormArray) {
    for (let i in group.controls) {
      if (group.controls[i] instanceof FormControl) {
        if (group.controls[i].invalid) {
        }
      } else {
        this.logInvalidFormControls(group.controls[i]);
      }
    }
  }

  public static getInvalidFormControlKeys(
    group: FormGroup | FormArray
  ): string[] {
    let keys: string[] = [];

    for (let i in group.controls) {
      if (group.controls[i] instanceof FormControl) {
        if (group.controls[i].invalid) {
          keys.push(i);
        }
      } else {
        keys.push(...this.getInvalidFormControlKeys(group.controls[i]));
      }
    }

    return keys;
  }

  public static markAsTouchedInvalidFormControls(
    group: FormGroup | FormArray
  ): FormControl[] {
    let keys: FormControl[] = [];

    for (let i in group.controls) {
      if (group.controls[i] instanceof FormControl) {
        if (group.controls[i].invalid) {
          (group.controls[i] as FormControl).markAsTouched();
        }
      } else {
        this.markAsTouchedInvalidFormControls(group.controls[i]);
      }
    }

    return keys;
  }

  public static updateValueAndValidityFormControls(
    group: FormGroup | FormArray
  ): FormControl[] {
    let keys: FormControl[] = [];

    for (let i in group.controls) {
      if (group.controls[i] instanceof FormControl) {
        (group.controls[i] as FormControl).updateValueAndValidity();
      } else {
        this.updateValueAndValidityFormControls(group.controls[i]);
      }
    }

    return keys;
  }

  isEmptyString(value: string): boolean {
    return !value || 0 === value.length;
  }

  
  public static base64ToBlob(b64Data, contentType, sliceSize = 0) {

    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {

        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);

        for (var i = 0; i < slice.length; i++) {

            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });

    return blob;
}
}
