import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CalculatorSummerDialogComponent } from '../calculator-summer-dialog/calculator-summer-dialog.component';
import { DictionaryService } from '../_services/dictionary.service';
import { PolicyService } from '../_services/policy.service';
import { CargoApplicationEditModel } from '../_types/_models/cargoApplicationModels';
import { DropDownModel } from '../_types/_models/dropDownModel';
import { CargoCalculatorModel } from './models/cargoCalculatorModel';
import { CargoCalculatorViewModel } from './models/cargoCalculatorViewModel';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import 'moment/locale/ka';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ka-GE' },    
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ApplicationComponent implements OnInit {
  policyForm: FormGroup;

  containerTypes: DropDownModel[];
  persons: DropDownModel[];

  isSubmeted: boolean;
  cargoCalculatorModel = {} as CargoCalculatorModel;

  minDate: Date;
  toDateMinDate: Date;
  maxDate: Date;
  model: CargoApplicationEditModel;
  applicationID: any;

  constructor(
    private formBuilder: FormBuilder,
    private dictionaryService: DictionaryService,
    private policyService: PolicyService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private changeDetector: ChangeDetectorRef
  ) {
    this.minDate = new Date();

    this.toDateMinDate = new Date(
      this.minDate.getFullYear(),
      this.minDate.getMonth(),
      this.minDate.getDate() + 1
    );
    this.maxDate = new Date(
      this.minDate.getFullYear(),
      this.minDate.getMonth() + 3,
      this.minDate.getDate()
    );
  }

  getDateFormatString(): string {
    if (this._locale === 'ka-GE') {
      return '';
    }
    return '';
  }

  ngOnInit(): void {
    this.dialog.closeAll();
    this.policyForm = this.formBuilder.group({
      beneficiaryID: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      containerTypeID: ['', Validators.required],
      containerNumbers: this.formBuilder.array([]),
    });
    this.route.params.subscribe((params) => {
      this.applicationID = params['id'];
    });
    if (this.applicationID) {
      this.route.data.subscribe((data) => {
        this.model = data.application.value;

        this.model.containers.forEach((c) => {
          this.addContainerNumber(c);
        });
      });
    } else {
      this.model = new CargoApplicationEditModel();
      this.addContainerNumber('');
    }

    this.loadDropdown();
  }

  containerNumbers(): FormArray {
    return this.policyForm.get('containerNumbers') as FormArray;
  }

  newContainerNumber(text: any): FormGroup {
    return this.formBuilder.group({
      containerNumber: [
        text,
        [
          Validators.required,
          Validators.minLength(9),
          Validators.pattern('^([a-zA-Z0-9]*)$'),
        ],
      ],
    });
  }

  addContainerNumber(text: any) {
    this.containerNumbers().push(this.newContainerNumber(text));
  }

  removeQuantity(i: number) {
    this.containerNumbers().removeAt(i);
  }

  calculate() {
    this.isSubmeted = true;

    if (this.policyForm.valid) {
      var containerNumbersValue = this.containerNumbers().value.map(
        (i) => i.containerNumber
      );
      this.cargoCalculatorModel = Object.assign(
        this.cargoCalculatorModel,
        this.policyForm.value
      );
      this.cargoCalculatorModel.containers = containerNumbersValue;

      this.policyService.calculate(this.cargoCalculatorModel).subscribe(
        (response) => {
          this.openDialog(response.value);
        }
      );
    }
  }

  loadDropdown() {
    this.dictionaryService
      .getContainerTypes()
      .subscribe((response) => (this.containerTypes = response.value));

    this.dictionaryService
      .getPersons()
      .subscribe((response) => (this.persons = response.value));
  }

  minDateValidation($event) {
    if ($event) {
      this.maxDate = new Date($event.year(), $event.month() + 3, $event.date());

      this.toDateMinDate = new Date(
        $event.year(),
        $event.month()  ,
        $event.date() + 1
      );
    }
    this.changeDetector.detectChanges()
  }

  openDialog(viewModel: CargoCalculatorViewModel) {
    this.cargoCalculatorModel.applicationID = parseInt(this.applicationID);
    this.dialog.open(CalculatorSummerDialogComponent, {
      width: '30%',
      data: {
        calculatorEdit: this.cargoCalculatorModel,
        calculatorView: viewModel,
      },
    });
  }
}
