
<!-- გასასტილია მარიამ და შეგიძლია ზუსტად დიზაინის მიხედვით გადააკეთო და გადავუვლი მერე და დატით შევავსებ -->
<div class="bg-white rounded-20 w-full max-w-628">
    <div class="flex flex-col text-center items-center mb-30 mt-24">
        <div mat-button mat-dialog-close
            class="ml-auto bg-gray5 rounded-40 p-14 cursor-pointer transform hover:scale-105 transition duration-300 mr-20">
            <img src="../assets/images/icons/VectorX.png" alt="">
        </div>
        <div class="flex items-center flex-col">
            <span class="text-24 MYRIADGEOMTAVRULIBOLD mb-16 text-pink">გადაამოწმე</span>
            <span class="text-14 NotoSansGeorgianSemiBold text-gray700 mb-30">დარწმუნდი შენს მიერ შეყვანილი <br> კონტეინერის
              ნომრების სისწორეში</span>
        </div>

        <div class="w-full mb-30 overflow-auto section max-h-443">
            <div class="">
                <div class="ml-40 mr-49 border-b border-black border-opacity-10 py-16"
                    *ngFor="let item of data.containers">
                    <span class="uppercase tracking-20 text-14 font-bold text-blue text-opacity-80">
                        {{item}}
                    </span>
                </div>
            </div>
        </div>
        <div class="bg-pink200 px-20 py-16 flex w-full rounded-20 mb-20 border border-pink300 max-w-479">
            <div>
                <span class="text-gray700 text-14 NotoSansGeorgianSemiBold">პოლისის პრემია</span>
            </div>
            <div class="ml-auto">
                <span class="text-14 text-pink font-bold mr-2">{{data.premium}}</span>
                <span class="text-14 text-pink font-bold">$</span>
            </div>
        </div>
        <div class="flex px-20">
            <button class="mr-20 bg-white200 hover:bg-opacity-75 flex items-center py-22 px-54 rounded-20
        relative group before:rounded-20 hover:rounded-20 items-center flex
        before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center
        cursor-pointer" [routerLink]="['/application/',data.applicationID]">
                <img src="../assets/images/icons/Vector20.png" alt="" class="mr-12">
                <span class="text-gray700 text-16 MYRIADGEOMTAVRULIBOLD">რედაქტირება</span>
            </button>
            <button class="relative group bg-pink py-22 px-54 rounded-20  before:rounded-20 hover:rounded-20 items-center flex
        before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0
        before:origin-top before:transition before:duration-300 hover:before:scale-x-100
        hover:before:origin-buttom" (click)="savePolicy()">
                <img src="../assets/images/icons/Vector21.png" alt="" class="mr-12 relative">
                <span class="text-white text-16 MYRIADGEOMTAVRULIBOLD relative">დადასტურება</span>
            </button>
        </div>
    </div>
</div>