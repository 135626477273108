<section class="flex justify-center w-full bg-gray400">
  <div class="absolute w-full sm:block">
    <div class="flex">
      <img src="../assets/images/bg/bg1.png" class="flex h-400 w-full" />
    </div>
    <div class="absolute top-0 left-0 z-1 w-full h-400 bg"></div>
    <div class="absolute top-0 left-0 z-1 w-full h-400 bg-gradient-to-t from-gray400 to-transparent"></div>
  </div>
  <!-- bg /. -->

  <div class="w-full h-full flex items-center justify-center relative">
    <div class="max-w-1300 rounded-t-lg bg-white pt-20 mt-30 w-full">

      <div class="flex items-center border-b border-black border-opacity-10 pb-20 mx-30">
        <span class="text-24 MYRIADGEOMTAVRULIBOLD text-gray700">ახალი პოლისი</span>
        <div class="ml-auto bg-gray5 rounded-40 p-14 mr-8 cursor-pointer" routerLink="/">
          <img src="../assets/images/icons/VectorX.png" alt="" class="">
        </div>
      </div>

      <form [formGroup]="policyForm" (ngSubmit)="calculate()" novalidate>
        <div class="pt-44 w-full">
          <div class="md:grid md:grid-cols-2 mb-44">

            <div class="flex flex-col items-center md:border-r border-dashed border-black border-opacity-20 pb-114">
              <div class="flex flex-col mb-78 text-center">
                <span class="text-18 MYRIADGEOMTAVRULIBOLD text-gray700">პოლისის ინფორმაცია</span>
              </div>
              <section class="w-350">
                <!--  -->
                <div class="relative mb-24">
                  <label class="absolute cursor-text left-20 -top-10 bg-white transition-all duration-300 text-opacity-80 text-blue NotoSansGeorgianSemiBold text-14 px-5">საზღვაო ხაზი</label>
                  <select formControlName="beneficiaryID" [(ngModel)]="model.beneficiaryID"
                    class="bg-white border border-blue700 appearance-none rounded-12 text-14 text-blue text-opacity-80 w-full h-60 focus:outline-none cursor-pointer pl-20 pr-34"
                    [ngClass]="policyForm.controls['beneficiaryID'].hasError('required') && (policyForm.controls['beneficiaryID'].dirty || policyForm.controls['beneficiaryID'].touched || isSubmeted)  ? 'border-red':''">
                    <option class="" *ngFor="let item of persons" [ngValue]="item.id">{{item.text}}</option>
                  </select>
                  <div class="">
                    <img src="../assets/images/icons/Vector19.png" alt="" class="absolute m-20 mt-24 right-0 top-0">
                    <!-- active icon -->
                    <img src="../assets/images/icons/Vector18.png" alt=""
                      class="absolute m-20 mt-24 right-0 top-0 hidden">
                    <!-- active icon-->
                  </div>
                </div>
                <div class="relative mb-24">
                  <label class="absolute cursor-text left-20 -top-10 bg-white transition-all duration-300 text-opacity-80 text-blue NotoSansGeorgianSemiBold text-14 px-5" style="z-index: 1;">დაწყების თარიღი</label>
                  <mat-form-field appearance="none"
                    class="bg-white border border-blue700 appearance-none rounded-12 text-14 text-blue text-opacity-80 w-full h-60 focus:outline-none cursor-pointer pl-20 pr-6"
                    [ngClass]="(policyForm.controls['fromDate'].hasError('required') || policyForm.controls['fromDate'].status =='INVALID' )&& (policyForm.controls['fromDate'].dirty || policyForm.controls['fromDate'].touched || isSubmeted )  ? 'border-red':''">
                    <input matInput [matDatepicker]="dp" formControlName="fromDate" [(ngModel)]="model.fromDate" placeholder="დდ / თთ / წწწწ"
                      (ngModelChange)="minDateValidation($event)" min="{{minDate | date : 'yyyy-MM-dd' }}"
                      class="placeholder-primary placeholder-opacity-20 text-14" style="font-family: NotoSansGeorgianSemiBold; font-weight: 700;">
                    <mat-hint>{{getDateFormatString()}}</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="relative mb-24">
                  <label class="absolute cursor-text left-20 -top-10 bg-white transition-all duration-300 text-opacity-80 text-blue NotoSansGeorgianSemiBold text-14 px-5" style="z-index: 1;">დასრულების თარიღი</label>
                  <mat-form-field appearance="none"
                    class="bg-white border border-blue700 appearance-none rounded-12 text-14 text-blue text-opacity-80 w-full h-60 focus:outline-none cursor-pointer pl-20 pr-6"
                    [ngClass]="(policyForm.controls['toDate'].hasError('required') || policyForm.controls['toDate'].status =='INVALID' )&& (policyForm.controls['toDate'].dirty || policyForm.controls['toDate'].touched || isSubmeted )  ? 'border-red':''">
                    <input matInput [matDatepicker]="endDp" formControlName="toDate" placeholder="დდ / თთ / წწწწ"
                      min="{{toDateMinDate | date : 'yyyy-MM-dd' }}" [(ngModel)]="model.toDate"
                      max="{{maxDate | date : 'yyyy-MM-dd'}}"
                      class="placeholder-primary placeholder-opacity-20 text-14" style="font-family: NotoSansGeorgianSemiBold; font-weight: 700;">
                    <mat-hint>{{getDateFormatString()}}</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="endDp"></mat-datepicker-toggle>
                    <mat-datepicker #endDp></mat-datepicker>
                    
                  </mat-form-field>
                </div>
                <div class="relative">
                  <label class="absolute cursor-text left-20 -top-10 bg-white transition-all duration-300 text-opacity-80 text-blue NotoSansGeorgianSemiBold text-14 px-5">კონტეინერის ტიპი</label>
                  <select formControlName="containerTypeID" [(ngModel)]="model.containerTypeID"
                    class="bg-white border border-blue700 appearance-none rounded-12 text-14 text-blue text-opacity-80 w-full h-60 focus:outline-none cursor-pointer pl-20 pr-34"
                    [ngClass]="policyForm.controls['containerTypeID'].hasError('required') && (policyForm.controls['containerTypeID'].dirty || policyForm.controls['containerTypeID'].touched || isSubmeted)? 'border-red':''">
                    <option class="" *ngFor="let item of containerTypes" [ngValue]="item.id">{{item.text}}</option>
                  </select>
                  <div class="">
                    <img src="../assets/images/icons/Vector19.png" alt="" class="absolute m-20 mt-24 right-0 top-0">
                    <!-- active icon -->
                    <img src="../assets/images/icons/Vector18.png" alt=""
                      class="absolute m-20 mt-24 right-0 top-0 hidden">
                    <!-- active icon-->
                  </div>
                </div>
                <!--  -->
              </section>
            </div>
            <!-- left /. -->

            <div class="flex flex-col items-center">
              <div class="flex flex-col mb-53 text-center">
                <span class="text-18 MYRIADGEOMTAVRULIBOLD text-gray700 mb-5">კონტეინერის ნომრები</span>
                <span class="text-14 NotoSansGeorgianSemiBold text-gray700">შეიყვანე მინიმუმ ერთი კონტეინერის ნომერი</span>
              </div>
              <div>
                <div formArrayName="containerNumbers">
                  <section class="w-350" *ngFor="
              let conatinerNumber of containerNumbers().controls;
              let i = index" [formGroupName]="i">
                    <div class="relative mb-10">
                      <input type="text" formControlName="containerNumber"
                        class="border border-blue700 appearance-none rounded-12 text-14 text-blue text-opacity-80 font-bold tracking-3 w-full h-60 pl-20 pr-49 focus:outline-none cursor-pointer uppercase"
                        [ngClass]="containerNumbers().controls[i]['controls']['containerNumber']['errors'] !=null && (containerNumbers().controls[i]['controls']['containerNumber'].dirty || containerNumbers().controls[i]['controls']['containerNumber'].touched || isSubmeted)? 'border-red':''" />

                      <button *ngIf="containerNumbers().controls.length!=1" (click)="removeQuantity(i)"
                        class="absolute inset-y-0 right-0 flex items-center justify-center bg-gray5 rounded-40 cursor-pointer w-30 h-30 mt-14 mr-14">
                        <img src="../assets/images/icons/VectorX.png" alt="">
                      </button>

                      <span class="absolute cursor-text left-20 -top-10 bg-white transition-all duration-300 text-opacity-80 text-blue NotoSansGeorgianSemiBold text-14 px-5">კონტეინერის
                        ნომერი</span>
                    </div>
                    <div class="text-12 text-red50 font-bold ml-2 mb-10"
                      *ngIf="containerNumbers().controls[i]['controls']['containerNumber']['errors']?.['required'] && (containerNumbers().controls[i]['controls']['containerNumber'].dirty || containerNumbers().controls[i]['controls']['containerNumber'].touched)">
                      კონტეინერის ნომერი სავალდებულოა</div>
                    <div class="text-12 text-red50 font-bold ml-2 mb-10"
                      *ngIf="containerNumbers().controls[i]['controls']['containerNumber']['errors']?.['pattern']!=null">
                      კონტეინერის ნომერი უნდა შეიცავდეს მხოლოდ ლათინურ ასოებს და რიცხვით მნიშვნელობებს</div>
                    <div class="text-12 text-red50 font-bold ml-2 mb-10"
                         *ngIf="containerNumbers().controls[i]['controls']['containerNumber']['errors']?.['minlength']!=null">
                      კონტეინერის ნომერი უნდა შეადგენდეს მინიმუმ 9 სიმბოლოს
                    </div>
                  </section>

                </div>
                <button (click)="addContainerNumber('')"
                  class="bg-gray700 bg-opacity-5 hover:bg-opacity-10 rounded-8 items-center flex justify-center w-350 py-10">
                  <img src="../assets/images/icons/Vector17.png" alt="" class="mr-8">
                  <span class="text-gray700 NotoSansGeorgianSemiBold text-12">დაამატე სხვა კონტეინერი</span>
                </button>
              </div>
            </div>
            <!--right /. -->
          </div>

          <div class="flex items-center rounded-t-lg bg-gray1 mx-30 max-w-1300">
            <div class="ml-auto m-23">
              <button type="submit" appearance="none" class="rounded-12 bg-pink px-53 py-20 relative group before:rounded-12 hover:rounded-12
             before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
             before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom cursor-pointer">
                <span class="text-white MYRIADGEOMTAVRULIBOLD text-16 relative">შემდეგი</span>
              </button>
            </div>
          </div>
          <!-- footer /. -->

        </div>
      </form>
    </div>
  </div>

</section>
