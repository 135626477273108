<!-- <div class="flex justify-center w-full h-full">
    <div class="absolute top-0 right-0 hidden w-full h-full sm:block">
      <div class="flex items-center justify-center w-screen h-screen">
        <img src="../assets/images/bg/bg0.png"
          class="flex flex-col flex-wrap items-start justify-start w-full h-screen mx-auto" />
      </div>
      <div class="absolute top-0 left-0 z-1 w-full h-screen bg-gradient-to-b from-white to-transparent"></div>
    </div> -->
<!-- bg /. -->
<!-- <div class="container">
      <div class="relative z-2 max-w-678 h-749 ml-auto mr-auto bg-white rounded-40 flex items-center flex-col">
        <div class="max-w-598 w-full">
          <div class="flex justify-center mt-80 mb-80 pb-40 border-b border-blue100 border-opacity-5">
            <img src="../assets/images/icons/logo.png" alt="" class="flex">
          </div>
        </div> -->
<!-- logo /.-->
<!-- <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="w-full max-w-350">
          <div class="relative mb-24">
            <input type="text" formControlName="username"
              class="border border-gray appearance-none rounded-12 text-14 text-blue text-opacity-70 w-full h-60 pl-20 pt-14 focus:outline-none"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
            <label for="username"
              class="absolute cursor-text left-0 pl-20 pt-18 top-0 transition-all duration-300 text-opacity-80 text-blue
              NotoSansGeorgianSemiBold text-14">მომხმარებელი</label>
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required" class="text-12 text-red50 NotoSansGeorgianSemiBold ml-2">მომხმარებლის სახელი
                სავალდებულოა</div>
            </div>
          </div>

          <div class="relative mb-24">
            <input type="password" formControlName="password"
              class="border border-gray appearance-none rounded-12 text-14 text-blue text-opacity-70 w-full h-60 pl-20 pt-14 focus:outline-none"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <label for="password"
              class="absolute cursor-text left-0 pl-20 pt-18 top-0 transition-all duration-300 text-opacity-80 text-blue NotoSansGeorgianSemiBold text-14">პაროლი</label>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required" class="text-12 text-red50 NotoSansGeorgianSemiBold ml-2">მომხმარებლის პაროლი
                სავალდებულოა</div>
            </div>
          </div>

          <div class="items-center flex">
            <div class="block mb-36 flex items-center cursor-pointer">
              <label class="inline-flex items-center mr-12">
                <div
                  class="bg-white active:bg-pink border rounded-8 border-blue700 w-24 h-24 flex flex-shrink-0 justify-center items-center"
                  [ngClass]="(isChecked)? 'bg-pink':''"
                  >
                  <input type="checkbox" class="opacity-0 absolute" (change)="checked($event)">
                  <img src="../assets/images/icons/Vector11.png" alt="" class="fill-current hidden pointer-events-none">
                </div>
              </label>
              <span class="text-blue NotoSansGeorgianSemiBold text-14">დამახსოვრება</span>
            </div>
          </div>

          <div class="flex flex-col items-center justify-center mb-123">
            <button [disabled]="loading" class="cursor-pointer text-center focus:outline-none bg-pink h-64 rounded-12 w-full relative group before:rounded-12 hover:rounded-12 
              before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top before:transition
              before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom cursor-pointer">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              <span class="relative text-white font-bold text-16 MYRIADGEOMTAVRULIBOLD">ავტორიზაცია</span>
            </button>
            <div *ngIf="error" class="text-12 text-red50 NotoSansGeorgianSemiBold mt-24">პაროლი ან მომხმარებელი არასწორია</div>
          </div>

        </form> -->
<!-- formGroup /. -->
<!-- </div>
    </div> -->
<!-- container /. -->
<!-- </div> -->
<div class="flex w-full h-screen overflow-y-hidden">
  <!-- left part -->
  <div class="w-full h-screen flex flex-col justify-center">
    <!-- header -->
    <div class="top-[18px] absolute top-[60px] left-[60px]">
      <img src=" ../assets/images/icons/logo.png" alt="" class="flex">
    </div>

    <!-- form -->
    <div>
      <div class="m-auto max-w-350">
        <div class="mb-[32px]">
          <h1 class="text-[#292562] text-[24px] mb-[8px] firago-font-auth">ავტორიზაცია</h1>
          <!-- <p class="text-[#2B2366] text-[16px]">აქ უნდა იყოს მისასალმებელი ტექსტი</p> -->
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="w-full">
          <div class="relative mb-24">
            <input type="text" formControlName="username"
              class="border border-gray appearance-none rounded-12 text-14 text-blue text-opacity-70 w-full h-60 pl-20 pt-14 focus:outline-none"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
            <label for="username" class="absolute left-[18px] top-[-10px] px-[5px] bg-white text-[#9199B4] text-[14px] NotoSansGeorgianSemiBold
                  NotoSansGeorgianSemiBold text-14">მომხმარებელი</label>
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required" class="text-12 text-red50 NotoSansGeorgianSemiBold ml-2">
                მომხმარებლის
                სახელი
                სავალდებულოა</div>
            </div>
          </div>

          <div class="relative mb-24">
            <input type="password" formControlName="password"
              class="border border-gray appearance-none rounded-12 text-14 text-blue text-opacity-70 w-full h-60 pl-20 pt-14 focus:outline-none"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <label for="password"
              class="absolute absolute left-[18px] top-[-10px] px-[5px] bg-white text-[#9199B4] NotoSansGeorgianSemiBold text-[14px]">პაროლი</label>
            <!-- <div class="absolute right-[24px] top-[50%] transform translate-y-[-50%]">
              <svg id="closed_eye" data-name="closed eye" xmlns="http://www.w3.org/2000/svg" width="20" height="16"
                viewBox="0 0 20 16">
                <path id="Path_10046" data-name="Path 10046"
                  d="M4.8,10.2a2.651,2.651,0,0,0-.6-.5c-.6-.5-1.1-1-1.6-1.5A11.718,11.718,0,0,1,4.2,6.7,9.9,9.9,0,0,1,10,4.2a4.1,4.1,0,0,1,1.1.1l1.7-1.6A8.676,8.676,0,0,0,10,2.2c-5.5,0-10,6-10,6a21.447,21.447,0,0,0,3.3,3.3Z"
                  fill="#9199b4" />
                <path id="Path_10047" data-name="Path 10047"
                  d="M16,4.4l3.1-2.9L17.8,0,11.7,5.7A3.371,3.371,0,0,0,10,5.2a3.009,3.009,0,0,0-3,3,4.191,4.191,0,0,0,.4,1.5L2.2,14.5,3.6,16l2.8-2.6a8.988,8.988,0,0,0,3.6.8c5.5,0,10-6,10-6A20.46,20.46,0,0,0,16,4.4ZM10,7.2h0l-1,1A1,1,0,0,1,10,7.2Zm5.7,2.5c-1.4,1.1-3.5,2.5-5.7,2.5a4.477,4.477,0,0,1-2-.4l.9-.8a3.6,3.6,0,0,0,1.1.2,3.009,3.009,0,0,0,3-3c0-.3-.1-.6-.1-.9l1.7-1.6a14.629,14.629,0,0,1,1.3.9c.6.5,1.1,1,1.6,1.5A13.721,13.721,0,0,1,15.7,9.7Z"
                  fill="#9199b4" />
              </svg>
            </div> -->
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required" class="text-12 text-red50 NotoSansGeorgianSemiBold ml-2">
                მომხმარებლის
                პაროლი
                სავალდებულოა</div>
            </div>
          </div>

          <div class="items-center flex">
            <div class="block mb-36 flex items-center cursor-pointer">
              <label class="inline-flex items-center mr-12">
                <div
                  class="bg-white active:bg-pink border rounded-8 border-blue700 w-24 h-24 flex flex-shrink-0 justify-center items-center"
                  [ngClass]="(isChecked)? 'bg-pink':''">
                  <input type="checkbox" class="opacity-0 absolute" (change)="checked($event)">
                  <img src="../assets/images/icons/Vector11.png" alt="" class="fill-current hidden pointer-events-none">
                </div>
              </label>
              <span class="text-blue NotoSansGeorgianSemiBold text-14">დამიმახსოვრე</span>
            </div>
          </div>

          <div class="flex flex-col items-center justify-center">
            <button [disabled]="loading" class="cursor-pointer text-center focus:outline-none bg-pink h-[56px] rounded-12 w-full relative group before:rounded-12 hover:rounded-12 
                  before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top before:transition
                  before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom cursor-pointer">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              <span class="relative text-white font-bold text-16 MYRIADGEOMTAVRULIBOLD">ᲨᲔᲡᲕᲚᲐ</span>
            </button>
            <div *ngIf="error" class="text-12 text-red50 NotoSansGeorgianSemiBold mt-24">პაროლი ან მომხმარებელი
              არასწორია
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- footer -->
    <!-- <div class="ml-[60px] flex gap-[36px] text-[#2B2366] text-14 mb-[60px] underline">
      <a href="#">წესები და პირობები</a>
      <a href="#">კონტაქტი</a>
    </div> -->
  </div>

  <!-- right part -->
  <div class="w-full h-screen login_right px-[68px] flex items-center">
    <div class="text-white">
      <div class="ml-[23px]">
        <h1 class="firago-font-containers">
          <span class="block">კონტეინერების</span>
          <span class="block">დაზღვევა</span>
        </h1>
        <div class="w-[60px] h-[3px] bg-[#E30D68] mb-[28px]"></div>
      </div>

      <div class="p-[24px] border-[1px] border-solid border-white rounded-[8px] max-w-[624px] noto-sans-georgian">
        <p>Mycontainers.ge ახალი ციფრული პლატფორმა, რომლის დახმარებითაც კონტეინერის დაზღვევის შეძენა რამდენიმე წამში შეგიძლია.</p>
        <p>სწრაფად და მარტივად, ონლაინ სივრცეში, სადაზღვევო მენეჯერის ჩართულობის გარეშე წარმართავ საზღვაო ხაზებთან დაკავშირებულ
        პროცესებს:</p>
        <ul style="list-style-type: disc; margin-left: 16px;">
          <li style="list-style-type: disc;">ახალი სადაზღვევო პოლისის შექმნა</li>
          <li style="list-style-type: disc;">პრემიის გადახდა ონლაინ</li>
          <li style="list-style-type: disc;">ინვოისი დაგენერირება</li>
          <li style="list-style-type: disc;">პოლისების რეპორტი</li>
          <li style="list-style-type: disc;">პოლისის ბეჭდვა</li>
          <li style="list-style-type: disc;">პოლისების ფილტრაცია სხვადასხვა პარამეტრებით</li>
        </ul>
      </div>
    </div>
  </div>
</div>