<form [formGroup]="filterForm">
    <section class="flex justify-center w-full">
        <div class="pt-30 px-30 rounded-20 w-758">

            <div class="flex border-b border-black w-full border-opacity-10 mb-63 pb-30">
                <span class="text-gray700 text-24 MYRIADGEOMTAVRULIBOLD">ფილტრი</span>
                <div [mat-dialog-close]="cleared"
                    class="ml-auto bg-gray5 rounded-40 p-14 mr-8 cursor-pointer transform hover:scale-105 transition duration-300">
                    <img src="../assets/images/icons/VectorX.png" alt="">
                </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="items-center justify-center">
                <div class="flex-col flex text-center mb-24">
                    <span class="text-gray700 text-18 MYRIADGEOMTAVRULIBOLD mb-8">კონტეინერის ტიპი</span>
                    <span class="text-14 text-gray700 text-opacity-50 NotoSansGeorgianMedium">მონიშნე სტატუსი</span>
                </div>
                <span class="w-full items-center justify-center flex">
                    <span class="border-b border-black w-80pr opacity-10 mb-24"></span>
                </span>
            </div>
            <!--  -->
            <div class="flex justify-center mb-100">
                <button class="flex items-center rounded-30 px-12 py-12 mr-10 hover:bg-opacity-75"
                    (click)="toggleCheck(1)" [ngClass]="{'bg-pink ': st20checked, 'bg-gray50 ':!st20checked}">
                    <span class="mr-10 text-11  NotoSansGeorgianMedium"
                        [ngClass]="{'text-white': st20checked, 'text-gray700 text-opacity-80':!st20checked}">20'
                        სტანდარტული</span>
                    <img src="../assets/images/icons/Vector13.png" alt="" *ngIf="st20checked">
                </button>
                <button class="flex items-center rounded-30 px-12 py-12 mr-10 hover:bg-opacity-75"
                    (click)=toggleCheck(4) [ngClass]="{'bg-pink ': ref20checked, 'bg-gray50 ':!ref20checked}">
                    <span class="mr-10 text-11 text-gray700 text-opacity-80 NotoSansGeorgianMedium"
                        [ngClass]="{'text-white': ref20checked, 'text-gray700 text-opacity-80':!ref20checked}">20'
                        რეფიჯერატორით</span>
                    <img src="../assets/images/icons/Vector13.png" alt="" *ngIf="ref20checked">
                </button>
                <button class="flex items-center rounded-30 px-12 py-12 mr-10 hover:bg-opacity-75"
                    (click)=toggleCheck(2) [ngClass]="{'bg-pink ': st40checked, 'bg-gray50 ':!st40checked}">
                    <span class="mr-10 text-11 text-gray700 text-opacity-80 NotoSansGeorgianMedium"
                        [ngClass]="{'text-white': st40checked, 'text-gray700 text-opacity-80':!st40checked}">40'
                        სტანდარტული</span>
                    <img src="../assets/images/icons/Vector13.png" alt="" *ngIf="st40checked">
                </button>
                <button class="flex items-center rounded-30 px-12 py-12 hover:bg-opacity-75"
                    (click)=toggleCheck(5) [ngClass]="{'bg-pink ': ref40checked, 'bg-gray50 ':!ref40checked}">
                    <span class="mr-10 text-11 text-gray700 text-opacity-80 NotoSansGeorgianMedium"
                        [ngClass]="{'text-white': ref40checked, 'text-gray700 text-opacity-80':!ref40checked}">40'
                        რეფრიჯერატორით</span>
                    <img src="../assets/images/icons/Vector13.png" alt="" *ngIf="ref40checked">
                </button>
            </div>
            <!--  -->
            <div class="items-center justify-center">
                <div class="flex-col flex text-center mb-24">
                    <span class="text-gray700 text-18 MYRIADGEOMTAVRULIBOLD mb-8">პერიოდი</span>
                    <span class="text-14 text-gray700 text-opacity-50 NotoSansGeorgianMedium">მიუთითე პოლისის მოქმედების პერიოდი</span>
                </div>
                <span class="w-full items-center justify-center flex mb-24">
                    <span class="border-b border-black w-80pr opacity-10"></span>
                </span>
            </div>
            <!--  -->
            <div class="flex justify-center mb-100 gap-16">
                <div class="relative mb-16 flex items-center">
                    <label
                        class="absolute cursor-text left-20 -top-10 bg-white transition-all duration-300 text-opacity-80 text-blue NotoSansGeorgianSemiBold text-14 px-5"
                        style="z-index: 1;">დაწყება</label>
                    <mat-form-field appearance="none"
                        class="bg-white border border-blue700 appearance-none rounded-12 text-14 text-blue text-opacity-80 w-full h-60 focus:outline-none cursor-pointer pl-20 pr-6">
                        <input matInput [matDatepicker]="dp" formControlName="start" placeholder="დდ / თთ / წწწწ"
                        [(ngModel)]="data.startDate" class="placeholder-primary placeholder-opacity-20 text-14"
                            style="font-family: NotoSansGeorgianSemiBold; font-weight: 700;">
                        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="relative mb-16 flex items-center">
                    <label
                        class="absolute cursor-text left-20 -top-10 bg-white transition-all duration-300 text-opacity-80 text-blue NotoSansGeorgianSemiBold text-14 px-5"
                        style="z-index: 1;">დასრულება</label>
                    <mat-form-field appearance="none"
                        class="bg-white border border-blue700 appearance-none rounded-12 text-14 text-blue text-opacity-80 w-full h-60 focus:outline-none cursor-pointer pl-20 pr-6">
                        <input matInput [matDatepicker]="endDp" formControlName="end" placeholder="დდ / თთ / წწწწ"
                            [(ngModel)]="data.endDate" class="placeholder-primary placeholder-opacity-20 text-14"
                            style="font-family: NotoSansGeorgianSemiBold; font-weight: 700;">
                        <mat-datepicker-toggle matSuffix [for]="endDp"></mat-datepicker-toggle>
                        <mat-datepicker #endDp></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <!--  -->
            <div class="bg-gray1 px-30 py-30 flex rounded-t-20">
                <button class="flex items-center" (click)="clearFilter()">
                    <img src="../assets/images/icons/trash.png" alt="" class="mr-5">
                    <span class="text-14 text-pink hover:text-opacity-80 font-semibold" >გასუფთავება</span>
                </button>
                <button class="relative group bg-pink py-24 px-70 rounded-12 ml-auto before:rounded-12 hover:rounded-12 items-center flex
       before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center
        cursor-pointer" [mat-dialog-close]="true">
                    <span class="text-white text-16 MYRIADGEOMTAVRULIBOLD relative">გაფილტვრა</span>
                </button>
            </div>
            <!--  -->
        </div>
    </section>
    <!-- Filter /. -->
</form>