import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { appConfig, AppConfig } from "../_types/_configs";

@Injectable({
  providedIn: 'root',
})
export class AppInitializer {

  constructor(private _httpClient: HttpClient) {
  }

  init(): Promise<any> {

    return this._httpClient
      .get('./assets/configs/app-config.json')
      .toPromise()
      .then((response : AppConfig) => {

        appConfig.apiEndPoint = response.apiEndPoint;

        return appConfig;
      }).catch(err => {

        console.error(`Failed to Init(). Make sure app-config.json is accessible.`);
        return Promise.reject(err);
      });
  }
}
