import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenStoreService } from './token-store.service';
import { AppConfig, APP_CONFIG } from '../_types/_configs';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserToken: BehaviorSubject<string>;
  public currentUser: Observable<string>;

  constructor(private http: HttpClient, private tokenStoreService: TokenStoreService, @Inject(APP_CONFIG) private _appConfig: AppConfig) {
    this.currentUserToken = new BehaviorSubject<string>(JSON.parse(localStorage.getItem('AccessToken')));
    this.currentUser = this.currentUserToken.asObservable();
  }

  public get currentUserTokenValue(): String {
    return this.currentUserToken.value;
  }



  login(username: string, password: string) {
    return this.http.post<any>(`${this._appConfig.apiEndPoint}/authorize/login`, { username, password })
      .pipe(map((response) => {

        this.tokenStoreService.storeAuthTokens(response["value"]);
        this.currentUserToken.next(response);
        return response;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('AccessToken');
    this.currentUserToken.next(null);
  }
}
