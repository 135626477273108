import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { ApplicationService } from '../_services/application.service';
import {  CargoApplicationEditModel, CargoApplicationSummerModel } from '../_types/_models/cargoApplicationModels';

@Component({
  selector: 'app-calculator-summer-dialog',
  templateUrl: './calculator-summer-dialog.component.html',
  styleUrls: ['./calculator-summer-dialog.component.css']
})
export class CalculatorSummerDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CalculatorSummerDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data : CargoApplicationSummerModel,
    public applicationService: ApplicationService,
    private router: Router,
    public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveApplication(){

    let saveApplicationModel :  CargoApplicationEditModel = {
      applicationID: this.data.calculatorEdit.applicationID,
      toDate: this.data.calculatorEdit.toDate,
      fromDate: this.data.calculatorEdit.fromDate,
      containerTypeID: this.data.calculatorEdit.containerTypeID,
      beneficiaryID: this.data.calculatorEdit.beneficiaryID,
      premium: this.data.calculatorView.premium,
      resultHash: this.data.calculatorView.resultHash,
      containers: this.data.calculatorEdit.containers,
      risk: this.data.calculatorView.risk
    };

    this.applicationService.saveApplication(saveApplicationModel).subscribe((response) =>{
      this.dialogRef.close();
      const dialogRefSucces = this.dialog.open(SuccessDialogComponent, {
        width: '350px',
        data: { text: 'განაცხადი წარმატებით შეინახა' },
      });

     


      
      dialogRefSucces.afterClosed().subscribe((result) => {
        this.router.navigate(['/']);
      });
    }
    );

  }

}
