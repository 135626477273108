 

import * as jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';

// Services 

import { BrowserStorageService } from './browser-storage.service';
// Types 

import { AuthTokenEnum } from '../_types/_enums/auth-token.enum';
import { IdentityToken } from '../_types/_models/auth-tokens';
import { UtilsService } from './utils.service';

@Injectable({ providedIn: 'root' })
export class TokenStoreService {

    constructor(private browserStorageService: BrowserStorageService, private utilsService: UtilsService
       ) {
    }


    public storeAuthTokens(response: any): void { 
        this.setToken(AuthTokenEnum.AccessToken, response["accessToken"]);
        this.setToken(AuthTokenEnum.RefreshToken, response["refreshToken"]);
        this.setToken(AuthTokenEnum.IdentityToken, response["identityToken"]);
    }

    public deleteAuthTokens() {

        this.browserStorageService.removeLocal(AuthTokenEnum[AuthTokenEnum.AccessToken]);
        this.browserStorageService.removeLocal(AuthTokenEnum[AuthTokenEnum.RefreshToken]);
        this.browserStorageService.removeLocal(AuthTokenEnum[AuthTokenEnum.IdentityToken]);
    }

    public getRawAuthToken(tokenType: AuthTokenEnum): string {

        return this.browserStorageService.getLocal(AuthTokenEnum[tokenType]);
    }

    public isAuthTokenExpired(token: AuthTokenEnum): boolean {

        const expirationDateUtc = this.getAuthTokenExpirationDateUtc(token);

        if (!expirationDateUtc) {
            return true;
        }

        return !(expirationDateUtc.valueOf() > new Date().valueOf());
    }

    public hasStoredAccessAndRefreshTokens(): boolean {

        const accessToken = this.getRawAuthToken(AuthTokenEnum.AccessToken);
        const refreshToken = this.getRawAuthToken(AuthTokenEnum.RefreshToken);

        return !this.utilsService.isEmptyString(accessToken) && !this.utilsService.isEmptyString(refreshToken);
    }

    public getIdentityToken(): IdentityToken {

        const identityToken: IdentityToken = JSON.parse(this.getRawAuthToken(AuthTokenEnum.IdentityToken));
        return identityToken;
    }

    public getDecodedRoles(): string[] {

        const decodedToken = this.getDecodedAuthToken(AuthTokenEnum.AccessToken);
        const roles = decodedToken["role"];

        if (!roles) {
            return [];
        }

        if (Array.isArray(roles)) {
            return roles.map(role => role);
        } else {
            return [roles];
        }
    }

    public getAuthUserDisplayName(): string {

        return this.getIdentityToken().fullName;
    }


    private setToken(tokenType: AuthTokenEnum, tokenValue: string): void {

        if (this.utilsService.isEmptyString(tokenValue)) {
            console.error(`${AuthTokenEnum[tokenType]} is null or empty.`);
        }

        if (tokenType === AuthTokenEnum.AccessToken && this.utilsService.isEmptyString(tokenValue)) {
            throw new Error("AccessToken can't be null or empty.");
        }
        this.browserStorageService.setLocal(AuthTokenEnum[tokenType], tokenValue);
    }

    private getDecodedAuthToken(token: AuthTokenEnum): any {

        const rawToken = this.getRawAuthToken(token);

        return rawToken ? jwt_decode(rawToken) : null;
    }

    private getAuthTokenExpirationDateUtc(token: AuthTokenEnum): Date | null {

        const decoded = this.getDecodedAuthToken(token);

        if (!decoded || decoded.exp === undefined) {
            return null;
        }

        const date = new Date(0); // The 0 sets the date to the epoch
        date.setUTCSeconds(decoded.exp);

        return date;
    }

}
