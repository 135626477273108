import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guideline',
  templateUrl: './guideline.component.html',
  styleUrls: ['./guideline.component.css']
})
export class GuidelineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
