import {
  trigger,
  transition,
  style,
  animate,
  state,
  query,
  stagger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PolicyFilterModalComponent } from '../policy-filter-modal/policy-filter-modal.component';
import { PolicyInfoDialogModel } from '../policy-info-dialog/policy-info-dialog-model';
import { PolicyInfoDialogComponent } from '../policy-info-dialog/policy-info-dialog.component';
import { CommonService } from '../_services/common.service';
import { PolicyService } from '../_services/policy.service';
import {
  PolicyAllDebetViewModel,
  PolicyFilterModel,
  PolicyGridViewModel,
} from '../_types/_models/policyModel';

@Component({
  selector: 'app-policy-grid',
  templateUrl: './policy-grid.component.html',
  styleUrls: ['./policy-grid.component.css'],
  animations: [
    trigger('flyInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1000ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class PolicyGridComponent implements OnInit {
  policyFilterData: PolicyFilterModel = new PolicyFilterModel();

  data: PolicyGridViewModel;
  policiesWithAllDebet : PolicyAllDebetViewModel;
  page: number = 1;
  searchOpened: boolean;
  searchText: string;
  filtered: boolean;
  policyIds: number[] = [];
  allDebetGel: number = 0;
  isAllChecked: boolean;
  totalPolicyCount: number;

  @Output() outputAllDebetGel = new EventEmitter<{allDebetGel:number, policyIds: number[]}>();
  // @Output() outputPolicyIds : EventEmitter<any> = new EventEmitter();


  @ViewChild('searchInput') searchElement: ElementRef;
  filteredWithSearch: boolean;
  constructor(
    public policyService: PolicyService,
    public dialog: MatDialog,
    private commonService: CommonService
  ) {}

  start;
  last;

  ngOnInit(): void {
    this.policyFilterData.page = 1;
    this.getPolicies(this.policyFilterData);

  }

  getPolicies(filterModel: PolicyFilterModel) {
    this.policyService.getPolicies(filterModel).subscribe((response) => {
      this.data = response.value;
      this.checkCheckedPolicies();
    });
  }

  onChangePage($event) {
      this.page = $event;
      this.policyFilterData.page = $event;
      this.policyFilterData.getAll=false;
      this.getPolicies(this.policyFilterData);
  }

  openPolicyIfo(id) {
    var policy = this.data.policies.find((x) => x.id == id);
    if (policy) {
      var data: PolicyInfoDialogModel = {
        beneficiaryFullName: policy.beneficiaryName,
        containerTypeText: policy.containerTypeText,
        containers: policy.containers.split(','),
        fromDate: this.formatDateToString(policy.fromDate),
        toDate: this.formatDateToString(policy.toDate),
        policyNumber: policy.policyNumber,
        premium: policy.premiumUsd,
        id: policy.id,
      };

      const dialogRef = this.dialog.open(PolicyInfoDialogComponent, {
        data: data,
      });
    }
  }

  printPolicyPdfById(policyId) {
    this.commonService.printPolicyPdfById(policyId);
  }

  formatDateToString(dateIn: Date) {
    var date = new Date(dateIn);
    return (
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '.' +
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '.' +
      date.getFullYear()
    );
  }

  getReport() {
    this.commonService.getReport(this.policyFilterData);
  }

  openFilter() {
    this.checkAll(false);
    var localFilter = JSON.parse(JSON.stringify(this.policyFilterData));
    const dialogRef = this.dialog.open(PolicyFilterModalComponent, {
      data: localFilter,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == true || localFilter.cleared) {
        this.filtered = !localFilter.cleared;
        localFilter.cleared = false;

        this.page=0;
        this.policyFilterData = JSON.parse(JSON.stringify(localFilter));
        this.policyFilterData.page = 0;
        this.policyFilterData.getAll= false;
        this.getPolicies(this.policyFilterData);
      } else {
      }
    });
  }

  search() {
    this.checkAll(false);
    this.page=0;
    this.policyFilterData= new PolicyFilterModel();
    this.policyFilterData.searchText = this.searchText;
    this.policyFilterData.getAll = false;
    this.getPolicies(this.policyFilterData);
  }

  openSearch() {
    this.searchOpened = true;
    this.filtered = false;
    this.filteredWithSearch = true;

    setTimeout(() => {
      //
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  closeSearch() {
    this.searchOpened = false;
    this.searchText = null;
    
    this.filteredWithSearch = false;
    
    this.page=0;
    this.policyFilterData= new PolicyFilterModel();
    this.checkAll(false);
    this.getPolicies( this.policyFilterData);
  }

  clearFilter() {
    this.page=0;
    this.filtered = false;
    this.policyFilterData = new PolicyFilterModel();
    this.checkAll(false);
    this.getPolicies(this.policyFilterData);
  }

  checkAll(event){

    this.policyIds.splice(0, this.policyIds.length);

    if(event){
      this.policyFilterData.getAll = event;
      this.policyService.getPolicies(this.policyFilterData).subscribe((response) => {
        this.policiesWithAllDebet = response.value.policyAllDebet;
        this.policyIds = this.policiesWithAllDebet.policyIds;
        this.allDebetGel = this.policiesWithAllDebet.allDebet;
        this.checkCheckedPolicies();
        this.totalPolicyCount = this.policiesWithAllDebet.count;
        this.isAllChecked=true;
      });
    }else{
      this.isAllChecked=false;
      this.allDebetGel = 0;
      this.data.policies.map(function(x){
        x.isChecked = false
      })
    }
    
    this.outputAllDebetGel.emit({allDebetGel : this.allDebetGel, policyIds : this.policyIds});
    // this.outputPolicyIds.emit(this.policyIds);

  }

  checkCheckedPolicies(){
    this.data.policies.forEach(element => {
     this.policyIds.find(x=>{
      if(x==element.id){
        this.checkedItem(element.id);
      }
    })
    

  });

  this.outputAllDebetGel.emit({allDebetGel : this.allDebetGel, policyIds : this.policyIds});
}

  checkedItem(policyId){
    let objIndex = this.data.policies.findIndex(x=>x.id == policyId);
    let policyIdIndex = this.policyIds.indexOf(policyId);

    this.data.policies[objIndex].isChecked = !this.data.policies[objIndex].isChecked;
    if(this.data.policies[objIndex].isChecked){
      
      if (policyIdIndex == -1) {
        this.policyIds.push(policyId);
        if(this.data.policies[objIndex].allDebetGel>0){
          this.allDebetGel = this.allDebetGel + this.data.policies[objIndex].allDebetGel;
        }       
      }

    }else{ 
      this.policyIds.splice(policyIdIndex,1);
      if(this.data.policies[objIndex].allDebetGel>0){
      this.allDebetGel = this.allDebetGel - this.data.policies[objIndex].allDebetGel;
      }
    }
    this.outputAllDebetGel.emit({allDebetGel :  Math.round(this.allDebetGel * 100)/100, policyIds : this.policyIds});
    
    if(this.totalPolicyCount!=this.policyIds.length){
      this.isAllChecked = false;
    }else{
      this.isAllChecked = true;
    }
  }
}
