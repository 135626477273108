import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services';
import { TokenStoreService } from '../_services/token-store.service';
import { AuthTokenEnum } from '../_types/_enums/auth-token.enum';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private tokenStoreService: TokenStoreService, private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        
        const accessToken = this.tokenStoreService.getRawAuthToken(AuthTokenEnum.AccessToken);
        if (accessToken) {
            request = request.clone({
                headers: request.headers.set("Authorization", `Bearer ${accessToken}`)
            });
        }

        return next.handle(request);
    }
}