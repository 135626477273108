import { Component, OnInit, ViewChild } from '@angular/core';
import { PolicyGridComponent } from '../policy-grid/policy-grid.component';
import { PolicyAllDebetViewModel } from '../_types/_models/policyModel';


@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})
export class GridComponent implements OnInit {

  showPolicyGrid: boolean;
  showApplicationGrid: boolean;
  title: string = "ჩემი განცხადებები";
  policy: PolicyAllDebetViewModel = {allDebet: 0};
  allDebetGel: number = 0;
  policyIds: number[] = [];
  
  @ViewChild(PolicyGridComponent) policyGrid:PolicyGridComponent;
  constructor() { }

  ngOnInit(): void {
    this.showPolicyGrid = true;
  }

  allDebetViewFromPolicyGrid(event){
    this.policy.allDebet = event.allDebetGel;
    this.policy.policyIds = event.policyIds
  }

  checkedPolicyIds(policyIds){
    this.policyIds = [1];
  }
  
  changeTab(i) {
    if (i == 1) {
      this.showApplicationGrid = true;
      this.showPolicyGrid = false;
      this.title = "ჩემი განცხადებები"
    }

    if (i == 2) {
      this.showApplicationGrid = false;
      this.showPolicyGrid = true;
      this.title = "ჩემი პოლისები"
    }
  }

  
  onGetReport(){
    this.policyGrid.getReport();
  }
}
