<div class="bg-white rounded-20 w-full max-w-628">
  <div class="flex mt-24">
    <div mat-button mat-dialog-close
      class="ml-auto bg-gray5 rounded-40 p-14 cursor-pointer transform hover:scale-105 transition duration-300 mr-20">
      <img src="../assets/images/icons/VectorX.png" alt="">
    </div>
  </div>
  <div class="section h-700">
    <div class="flex flex-col text-center">
      <div class="flex flex-col text-center items-center mb-30 mt-8 overflow-auto section max-h-555">
        <div class="flex mb-8">
          <span class="flex justify-center items-center pr-5">
            <i class="w-8 h-8 bg-green rounded-40 -mt-4"></i>
          </span>
          <span class="md:text-12 text-10 NotoSansGeorgianSemiBold text-gray700">
            აქტიური პოლისი
          </span>
        </div>

        <p class="mb-16">
          <span class="text-gray700 MYRIADGEOMTAVRULIBOLD text-18">{{data.beneficiaryFullName}}</span>
        </p>

        <span class="bg-gray400 px-20 py-10 rounded-20 flex mb-20 cursor-pointer">
          <span><img src="../assets/images/icons/calendar.png" alt="" class="w-16 h-16 mr-12"></span>
          <span class="pr-5">{{data.fromDate}}</span>-<span class="pl-5">{{data.toDate}}</span>
        </span>
        <div style="margin-left: 70px; margin-right: 83px;">
          <div class="border-b border-opacity-10 border-black w-468 mb-20 flex">
            <div class="mr-auto flex flex-col items-start">
              <span class="text-gray700 text-opacity-50 text-14 NotoSansGeorgianSemiBold">პოლისის ნომერი</span>
              <span class="text-gray700 text-opacity-80 text-14 pb-12 font-semibold">{{data.policyNumber}}</span>
            </div>
          </div>
          <div class="border-b border-opacity-10 border-black w-468 mb-20 flex">
            <div class="mr-auto flex flex-col items-start">
              <span class="text-gray700 text-opacity-50 text-14 NotoSansGeorgianSemiBold">კონტეინერის ტიპი</span>
              <span class="text-gray700 text-opacity-80 text-14 pb-12 font-semibold">{{data.containerTypeText}}</span>
            </div>
          </div>

          <div class="border-b border-opacity-10 border-black w-468 mb-20 flex">
            <div class="mr-auto flex flex-col items-start">
              <span class="text-gray700 text-opacity-50 text-14 NotoSansGeorgianSemiBold">გადაზიდვის ტიპი</span>
              <span class="text-gray700 text-opacity-80 text-14 pb-12 font-semibold">სახმელეთო</span>
            </div>
          </div>

          <div class="border-b border-opacity-10 border-black w-468 flex">
            <div class="mr-auto flex flex-col items-start">
              <span class="text-gray700 text-opacity-50 text-14 NotoSansGeorgianSemiBold">პრემია</span>
              <span class="text-gray700 text-opacity-80 text-14 pb-12 font-semibold">{{data.premium}} $</span>
            </div>
          </div>
        </div>

        <div class="flex flex-col mt-30">
          <span class="text-gray700 text-16 MYRIADGEOMTAVRULIBOLD mb-5">დაზღვეული კონტეინერები</span>
        </div>

        <div class="w-full mb-30">
          <div class="">
            <div class="ml-40 mr-49 border-b border-black border-opacity-10 py-16" *ngFor="let item of data.containers">
              <span class="uppercase tracking-20 text-14 font-bold text-blue text-opacity-80">
                {{item}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex gap-10 justify-center mb-20">
        <button (click)="print()"
          class="bg-white200 w-170 py-18 relative group rounded-20 before:rounded-20 hover:rounded-20 items-center
        flex before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
        before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center cursor-pointer">
          <img src="../assets/images/icons/Vector16.png" alt="" class="mr-8 relative">
          <span class="text-14 font-bold text-gray700 relative">საბეჭდი ფორმა</span>
        </button>
        <button (click)="resendPurchaseEmail()"
          class="bg-white200 w-170 text-left py-10 relative group rounded-20 before:rounded-20 hover:rounded-20 items-center
         flex before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
         before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center cursor-pointer">
          <img src="../assets/images/icons/Vector15.png" alt="" class="mr-8 relative">
          <span class="text-14 font-bold text-gray700 relative">საზღვაო ხაზთან <br> გაგზავნა</span>
        </button>
        <button
          class="bg-white200 w-170 text-left py-10 relative group rounded-20 before:rounded-20 hover:rounded-20 items-center
         flex before:absolute before:inset-0 before:bg-opacity-10 before:bg-black before:scale-x-0 before:origin-top
         before:transition before:duration-300 hover:before:scale-x-100 hover:before:origin-buttom justify-center cursor-pointer">
          <img src="../assets/images/icons/Vector2.png" alt="" class="mr-8 relative">
          <span class="text-14 font-bold text-gray700 relative">ინვოისი</span>
        </button>
      </div>
    </div>
  </div>
</div>
