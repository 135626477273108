import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../_services';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, public dialog: MatDialog) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => { 
            debugger
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload();
            }else if(err.status === 400){

                var errorMessages = err.error.messages;

                this.dialog.open(ErrorDialogComponent, {
                    width: '350px',
                    data: { text: errorMessages},
                  });
            }else if(err.status ==500){
                this.dialog.open(ErrorDialogComponent, {
                    width: '350px',
                    data: { text: ["დაფიქსირდა გაუთვალისწინებელი შემთხვევა"]},
                  });
            }else{
                this.dialog.open(ErrorDialogComponent, {
                    width: '350px',
                    data: { text: ["დაფიქსირდა გაუთვალისწინებელი შემთხვევა"]},
                  });
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}