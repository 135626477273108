import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { PolicyService } from '../_services/policy.service';
import { PolicyFilterModel } from '../_types/_models/policyModel';

@Component({
  selector: 'app-policy-filter-modal',
  templateUrl: './policy-filter-modal.component.html',
  styleUrls: ['./policy-filter-modal.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ka' },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class PolicyFilterModalComponent implements OnInit {
  filterForm: FormGroup;

  endDate: Date;
  startDate: Date;

  st20checked: boolean;
  st40checked: boolean;
  ref40checked: boolean;
  ref20checked: boolean;
  cleared: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PolicyFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PolicyFilterModel,
    private policyService: PolicyService,
    public dialog: MatDialog,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      start: [this.data.startDate],
      end: [this.data.endDate],
    });
     this.fillSelectedTypes();
  }

  getDateFormatString(): string {
    if (this._locale === 'ka') {
      return '';
    }
    return '';
  }
  toggleCheck(type: number) {
    var index = this.data.ContainerTypes.findIndex((x) => x == type);
    if (index != -1) {
      this.data.ContainerTypes.splice(index, 1);
    }
    switch (type) {
      case 1:
        this.st20checked = !this.st20checked;
        if (this.st20checked) this.data.ContainerTypes.push(1);
        break;
      case 4:
        this.ref20checked = !this.ref20checked;
        if (this.ref20checked) this.data.ContainerTypes.push(4);
        break;
      case 2:
        this.st40checked = !this.st40checked;
        if (this.st40checked) this.data.ContainerTypes.push(2);
        break;
      case 5:
        this.ref40checked = !this.ref40checked;
        if (this.ref40checked) this.data.ContainerTypes.push(5);
        break;
    }
  }

  clearFilter() {
    this.cleared = true;
    this.data.ContainerTypes = [];
    this.data.startDate = undefined;
    this.data.endDate = undefined;
    this.fillSelectedTypes();
    this.data.cleared = true;
  }

  fillSelectedTypes(){
    this.st20checked =false;
    this.ref20checked =false;
    this.st40checked =false;
    this.ref40checked =false;
    this.data.ContainerTypes.forEach((element) => {
      switch (element) {
        case 1:
          this.st20checked = true;
          break;
        case 4:
          this.ref20checked = true;
          break;
        case 2:
          this.st40checked = true;
          break;
        case 5:
          this.ref40checked = true;
          break;
      }
    });
  }
}
