<div class="bg-white rounded-20 w-full max-w-479">
  <div class="flex flex-col text-center items-center mt-24">
    <div
      class="ml-auto bg-gray5 rounded-40 p-14 cursor-pointer transform hover:scale-105 transition duration-300 mr-20 mb-10"
      (click)="close()">
      <img src="../assets/images/icons/VectorX.png" alt=""></div>
    <div class="mx-60 flex flex-col items-center justify-center">
      <div class="w-42 bg-red200 h-42 rounded-40 flex justify-center items-center mb-16">
        <img src="../assets/images/icons/Vector12.png" alt=""
          class="w-20 transform hover:scale-105 transition duration-300">
      </div>
      <div class="mb-8" *ngFor="let item of data.text">
        <p class="text-18 font-bold text-gray700 w-310">{{item}} </p>
      </div>
      <div class="mb-60">
        <p class="text-12 font-semibold text-gray700 mb-60">{{data.additionalText}}</p>
      </div>
    </div>
  </div>
</div>
