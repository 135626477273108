
<!-- <app-application-grid></app-application-grid> -->

<section class="flex justify-center w-full bg-gray400 overflow-hidden" style="height: calc(100vh - 100px);">
    <div class="absolute w-full sm:block">
      <div class="flex">
        <img src="../assets/images/bg/bg1.png" class="flex h-400 w-full" />
      </div>
      <div class="absolute top-0 left-0 z-1 w-full h-400 bg"></div>
      <div class="absolute top-0 left-0 z-1 w-full h-400 bg-gradient-to-t from-gray400 to-transparent"></div>
    </div>
    <!-- bg /. -->
    <div class="max-w-1300 w-1300 justify-center mt-30">
      <div class="gap-20 relative md:flex" style="height: 100%;">
        <div class="bg-white rounded-12 md:max-w-860 md:w-full mb-20"
          style="display: flex;flex-direction: column;">
  
          <div class="w-full md:pl-30 md:py-29 pl-12 py-12">
            <span class="gray700 md:text-18 MYRIADGEOMTAVRULIBOLD">{{title}}</span>
          </div>
          <!-- title -->
          <div class="w-full bg-gray800 block">
            <div class="flex justify-center">
              <ul class="flex">
                <li class="relative flex justify-center mx-30 group" (click)="changeTab(2)">
                  <a class="py-22 flex text-14 text-gray700 MYRIADGEOMTAVRULIBOLD cursor-pointer"
                    [ngClass]="{ 'text-opacity-50': showApplicationGrid }">პოლისები</a>
                  <i class="h-2 w-123 rounded-t-12 bg-blue absolute bottom-0" [hidden]="showApplicationGrid"></i>
                </li>
                <span class="border-r border-gray my-20"></span>
                <li class="relative flex justify-center mx-30 group" (click)="changeTab(1)">
                  <a class="py-22 flex text-14 text-gray700 MYRIADGEOMTAVRULIBOLD cursor-pointer"
                    [ngClass]="{ 'text-opacity-50': showPolicyGrid }">განაცხადები</a>
                  <i class="h-2 w-123 rounded-t-12 bg-blue absolute bottom-0" [hidden]="showPolicyGrid"></i>
                </li>
              </ul>
            </div>
          </div>
          <div class="section">
            <app-application-grid *ngIf="showApplicationGrid"></app-application-grid>
          </div>
          <div class="section">
          <app-policy-grid *ngIf="showPolicyGrid" (outputAllDebetGel)="allDebetViewFromPolicyGrid($event)"></app-policy-grid>
          </div>
  
        </div>
        <!-- left -->
        <div class="bg-white rounded-12  md:max-w-417 w-full h-fit">
          <app-action-buttons [policy]="policy" [allDebetGel]="allDebetGel" [policyIds]="policyIds" (getReportClicked)="onGetReport()"></app-action-buttons>
        </div>
        <!-- right -->
      </div>
    </div>
  </section>
  