import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '../_types/_configs';
import { ApplicationFilterModel, CargoApplicationEditModel } from '../_types/_models/cargoApplicationModels';

@Injectable({ providedIn: 'root' })
export class ApplicationService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private _appConfig: AppConfig) { }

  saveApplication(model: CargoApplicationEditModel) {
    return this.http.post<any>(`${this._appConfig.apiEndPoint}/Application/saveapplication`, model);
  }

  getApplications(model: ApplicationFilterModel) {
    return this.http.post<any>(`${this._appConfig.apiEndPoint}/Application/getapplications`, model);
  }

  getApplication(id: any) {
    return this.http.get<any>(`${this._appConfig.apiEndPoint}/Application/getapplication/${id}`);
  }

  deleteApplication(applicationID: any) {
    return this.http.post<any>(`${this._appConfig.apiEndPoint}/Application/delete`, applicationID);
  }
}
