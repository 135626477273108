import { Injectable } from '@angular/core';

// Types 

import { AuthTokenEnum } from '../_types/_enums/auth-token.enum';
import { BrowserStorageEnum } from '../_types/_enums/browser-storage.enum';

@Injectable({ providedIn: 'root' })
export class BrowserStorageService {

  public getSession(key: string): any {

    const data = window.sessionStorage.getItem(key);

    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  public setSession(key: string, value: any): void {

    const data = value === undefined ? "" : JSON.stringify(value);

    window.sessionStorage.setItem(key, data);
  }

  public removeSession(key: string): void {

    window.sessionStorage.removeItem(key);
  }

  public removeAllSessions(withAuthTokens: boolean = false): void {

    for (const key in window.sessionStorage) {

      if (window.sessionStorage.hasOwnProperty(key) &&
        key != AuthTokenEnum[AuthTokenEnum.AccessToken] && key != AuthTokenEnum[AuthTokenEnum.RefreshToken] && key != AuthTokenEnum[AuthTokenEnum.IdentityToken]) {

        this.removeSession(key);
      }
    }
  }


  public getLocal(key: string): any {

    const data = window.localStorage.getItem(key);

    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  public setLocal(key: string, value: any): void {

    const data = value === undefined ? "" : JSON.stringify(value);

    window.localStorage.setItem(key, data);
  }

  public removeLocal(key: string): void {

    window.localStorage.removeItem(key);
  }

  public removeAllLocals(withAuthTokens: boolean = false): void {

    for (const key in window.localStorage) {

      if (window.localStorage.hasOwnProperty(key) &&
        key != AuthTokenEnum[AuthTokenEnum.AccessToken] && key != AuthTokenEnum[AuthTokenEnum.RefreshToken] && key != AuthTokenEnum[AuthTokenEnum.IdentityToken]) {

        this.removeLocal(key);
      }
    }
  }


  public setTempData(key: string, value: any, type: BrowserStorageEnum = BrowserStorageEnum.SessionStorage) {

    const data = value === undefined ? "" : JSON.stringify(value);

    if (type == BrowserStorageEnum.SessionStorage)
      this.setSession(`TempData_${key}`, data);
    else
      this.setLocal(`TempData_${key}`, data);
  }

  public getTempData(key: string, type: BrowserStorageEnum = BrowserStorageEnum.SessionStorage) {

    let data = null;

    if (type == BrowserStorageEnum.SessionStorage) {

      data = this.getSession(`TempData_${key}`);
      this.removeSession(`TempData_${key}`);
    } else {

      data = this.getLocal(`TempData_${key}`);
      this.removeLocal(`TempData_${key}`);
    }

    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  }
}
