import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { PolicyService } from '../_services/policy.service';
import { CargoApplicationEditModel } from '../_types/_models/cargoApplicationModels';

@Component({
  selector: 'app-application-summary-dialog',
  templateUrl: './application-summary-dialog.component.html',
  styleUrls: ['./application-summary-dialog.component.css'],
})
export class ApplicationSummaryDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ApplicationSummaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CargoApplicationEditModel,
    private policyService: PolicyService,
    
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}
  savePolicy() {
    let model = new CargoApplicationEditModel();

    model.toDate = this.data.toDate;
    model.fromDate = this.data.fromDate;
    model.applicationID = this.data.applicationID;
    model.beneficiaryID = this.data.beneficiaryID;
    model.containerTypeID = this.data.containerTypeID;
    model.containers = this.data.containers;
    model.premium = this.data.premium;
    model.resultHash = this.data.resultHash;
    model.risk = this.data.risk;

    this.policyService.save(model).subscribe(
      (response) => {
        this.openDialog(response.value);
      }
    );
  }

  openDialog(value: any) { 
    this.dialogRef.close();
     var additionalText = `პოლისის ნომერი : ${value.policies[0].policyNumber}`
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      width: '350px',
      data: { text: 'პოლისი წარმატებით შეიქმნა', additionalText: additionalText },
    });
  }
}
