import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '../_types/_configs';

@Injectable({ providedIn: 'root' })
export class DictionaryService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) private _appConfig: AppConfig) { }

  getContainerTypes() {
    return this.http.get<any>(`${this._appConfig.apiEndPoint}/Dictionary/getcontainertypes`);
  }

  getPersons() {
    return this.http.get<any>(`${this._appConfig.apiEndPoint}/Dictionary/getpersons`);
  }
}
