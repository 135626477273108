import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationComponent } from './application/application.component';
import { GuidelineComponent } from './guideline/guideline.component';
import { HomeComponent } from './home';

import { LoginComponent } from './login/login.component';
import { PolicyGridComponent } from './policy-grid/policy-grid.component';
import { TransactionComponent } from './transaction/transaction.component';
import { AuthGuard } from './_helpers';
import { ApplicationResolver } from './_resolvers/application-resolver';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  {
    path: 'application',
    component: ApplicationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'policies',
    component: PolicyGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transaction' ,
    component: TransactionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'application/:id',
    component: ApplicationComponent,
    canActivate: [AuthGuard],
    resolve: {
      application: ApplicationResolver,
    },
  },
  { path: 'guid', component: GuidelineComponent, canActivate: [AuthGuard] },

  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
