import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { CommonService } from '../_services/common.service';
import { PolicyService } from '../_services/policy.service';
import { PolicyInfoDialogModel } from './policy-info-dialog-model';

@Component({
  selector: 'app-policy-info-dialog',
  templateUrl: './policy-info-dialog.component.html',
  styleUrls: ['./policy-info-dialog.component.css'],
})
export class PolicyInfoDialogComponent implements OnInit {

   
  constructor(
    private commonService: CommonService,
    private service: PolicyService,
    public dialogRef: MatDialogRef<PolicyInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PolicyInfoDialogModel,
    
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}
  print() {
    this.commonService.print(this.data.policyNumber);
  }

  resendPurchaseEmail() {
    this.service
      .resendPolicyPurchaseNotifications(this.data.id)
      .subscribe((response) => {
        if (response && response.value) { 
          const dialogRef = this.dialog.open(SuccessDialogComponent, {
            width: '350px',
            data: { text: 'შეტყობინება წარმატებით გაიგზავნა' },
          });

        }else{
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '350px',
            data: { text: 'შეტყობინება ვერ გაიგზავნა' },
          });
        }
      });
  }
}
